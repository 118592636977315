/*
This component was coded using a great part of code issued by Creative Tim in this template : 

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

*/
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "sciliciumLayouts/components/Footer";
import Comparison from "sciliciumLayouts/components/Comparison";

import APIService from "services/DjangoAPI";
import StatsGenolist from "sciliciumLayouts/components/StatsGenolist";
import ListGenes from "sciliciumLayouts/components/Tables/ListGenes";
import BarChart from "sciliciumLayouts/components/Charts/BarChart";
import BoxPlotsForGenocounts from "sciliciumLayouts/components/BoxPlotsForGenocounts";
import GenocountsSkeleton from "sciliciumLayouts/components/Skeletons/GenocountsSkeleton";
import DEGPlots from "sciliciumLayouts/components/DEGPlots";
import Enrichment from "sciliciumLayouts/components/Enrichment";

function Genolist() {
  const { id } = useParams();
  const [genolist, setGenolist] = useState("");
  const [listOfGenes, setListOfGenes] = useState([]);
  const [loading, setLoading] = useState("");

  const fetchGenolist = async () => {
    setLoading(true);
    try {
      const { data } = await APIService.getGenolist(id);
      setGenolist(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(async () => {
    await fetchGenolist();
    setLoading(false);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox py={3}>
          <MDBox mb={3}>
            <Card>
              <MDBox p={3}>
                <Skeleton variant="text" width="60%" />
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox py={3}>
          <MDBox mb={3}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h4" fontWeight="medium">
                  Project {genolist.project?.name}: {genolist.name}
                </MDTypography>
                <MDTypography variant="h6" color="dark">
                  {genolist.description}
                </MDTypography>
              </MDBox>
            </Card>
          </MDBox>
          {genolist["comparison"] !== undefined && genolist["name"] !== undefined ? (
            <div>
              <Comparison comparison={genolist.comparison} />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <StatsGenolist genolist={genolist} />
                  <BarChart deg={genolist.deg} comparison={genolist.comparison} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <ListGenes
                    genolistId={id}
                    typeGenolist={genolist.type_genolist}
                    setListOfGenes={setListOfGenes}
                    genolistName={genolist.name}
                    projectName={genolist.project.name}
                  />
                </Grid>
              </Grid>

              <Grid mt={1} container spacing={3} style={{ display: "flex", alignItems: "stretch" }}>
                {listOfGenes.length > 0 ? ( // Render only if listOfGenes is available
                  <Grid item xs={12} sm={6}>
                    <BoxPlotsForGenocounts listOfGenes={listOfGenes} genolist={genolist} />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <GenocountsSkeleton />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <DEGPlots
                    genolistName={genolist.name}
                    comparison={genolist.comparison}
                    genolistId={genolist.id}
                  />
                </Grid>
              </Grid>
              <Enrichment genolistId={id} />
            </div>
          ) : (
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Oops ! It seems we cannot find this genolist.
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Genolist;
