// Hooks
import { useState, useEffect, createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

// API
import APIService from "services/DjangoAPI";
//import { getAuthenticatedUserId } from "tokenHelper";

// UI Components
import { Backdrop } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "sciliciumLayouts/components/Footer";
import colors from "assets/theme/base/colors";

// Loader
import PacmanLoader from "react-spinners/PacmanLoader";
import UploadScreen from "./UploadScreen";
import ErrorsScreen from "./ErrorsScreen";
import ReviewScreen from "./ReviewScreen";
import DoneScreen from "./DoneScreen";
import AlertSnackbar from "sciliciumLayouts/components/AlertSnackbar";

/**
 * Enum-like object holding feature states
 */
const UploadState = Object.freeze({
  LOADING: Symbol("Loading"), // Only used when fetching project data
  UPLOAD: Symbol("Upload"),
  ERRORS: Symbol("Errors"),
  REVIEW: Symbol("Review"),
  DONE: Symbol("Done"), // Not used for now, maybe removed
});

export const UploadContext = createContext();

/**
 * This component is the Upload feature's index. It provides a context enabling all
 * other components to access the feature's states. It handles the global business
 * logic (displaying screens, make API service calls, store data objects and errors).
 */
const ProjectUpload = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [currentStep, setCurrentStep] = useState(UploadState.LOADING);
  const [businessErrors, setBusinessErrors] = useState();
  const [fatalErrors, setFatalErrors] = useState(null);
  const [toReview, setToReview] = useState();
  const [loading, setLoading] = useState(false);

  //const authenticatedUserId = getAuthenticatedUserId();

  const backToProject = () => navigate(`/projects/${id}`, { replace: true });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFatalErrors(null);
  };

  /**
   * Sends a GET request to backend with current project id and returns the file to be downloaded.
   */
  const download = async () => {
    try {
      let response = await APIService.getExcelFileByProject(projectData?.id);
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      return blob;
    } catch (error) {
      setFatalErrors(error.response.data.exceptions[0]);
    }
  };

  /**
   * Send the file to backend. File must be an Excel file.
   * Handle the response from server and updates state accordingly.
   */
  const upload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    try {
      const response = await APIService.postExcelFile(formData);
      setToReview(response.data);
      setCurrentStep(UploadState.REVIEW);
    } catch (error) {
      if (error.response.status === 422) {
        setBusinessErrors(error.response.data);
        setCurrentStep(UploadState.ERRORS);
      } else {
        setFatalErrors(error.response.data.exceptions[0]);
        setCurrentStep(UploadState.UPLOAD);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Sends a POST request with reviewed data.
   */
  const confirm = async () => {
    const form = toReview;
    setLoading(true);
    try {
      const response = await APIService.postProjectDesc(form);
      backToProject();
      setCurrentStep(UploadState.DONE);
    } catch (error) {
      setFatalErrors(error.response.data);
      setCurrentStep(UploadState.UPLOAD);
    } finally {
      setLoading(false);
    }
  };

  const context = {
    handleUploadClick: upload,
    handleDownloadClick: download,
    handleConfirmSubmit: confirm,
    errors: businessErrors,
    toReview: toReview,
    projectData: projectData,
  };

  useEffect(() => {
    const getProjectData = async (id) => {
      setLoading(true);
      try {
        const response = await APIService.getExtendedProject(id);
        setProjectData(response.data);
        if (response.data.status === "APPROVED") {
          setCurrentStep(UploadState.UPLOAD);
        } else {
          setFatalErrors({ detail: "Project must be approved before uploading data" });
          backToProject();
        }
      } catch (error) {
        backToProject();
      } finally {
        setLoading(false);
      }
    };
    getProjectData(id);
  }, [id]);

  return (
    <DashboardLayout>
      <Backdrop open={loading} sx={{ zIndex: 2000 }}>
        {loading && (
          <PacmanLoader
            size={75}
            color={colors.success.main}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Backdrop>
      <AlertSnackbar
        open={fatalErrors}
        onClose={handleCloseSnack}
        severity="error"
        message={fatalErrors?.detail}
      />
      <DashboardNavbar />
      <MDBox py={3} m="auto" display="flex" alignItems="center" justifyContent="center">
        <UploadContext.Provider value={context}>
          {currentStep === UploadState.UPLOAD && <UploadScreen />}
          {currentStep === UploadState.ERRORS && <ErrorsScreen />}
          {currentStep === UploadState.REVIEW && <ReviewScreen />}
          {currentStep === UploadState.DONE && <DoneScreen />}
        </UploadContext.Provider>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ProjectUpload;
