// Hooks
import { useContext, useEffect, useState } from "react";
import { UploadContext } from "..";

// UI Elements
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UploadButton from "sciliciumLayouts/components/Buttons/UploadButton";
import DownloadButton from "sciliciumLayouts/components/Buttons/DownloadButton";
import LinkButton from "sciliciumLayouts/components/Buttons/LinkButton";
import ErrorTile from "./errorsScreenTile";

const ErrorsScreen = () => {
  const context = useContext(UploadContext);
  const [nbErrors, setNbErrors] = useState();

  // Stores categorized errors UI blocks
  const [errorBlocks, setErrorBlocks] = useState();

  /**
   * Generates categories based on the "model" value in exceptions body.
   */
  function renderCategories(data) {
    // Generates an object with model as keys and list of errors as values
    const grouped = data.reduce((acc, currentValue) => {
      let groupKey = currentValue["model"];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {});

    // Returns category blocks with listed errors.
    return Object.keys(grouped).map((c) => (
      <>
        <MDTypography key={`${c}-h5`} variant="h5" fontWeight="medium">
          {c}
        </MDTypography>
        {grouped[c].map((m) => (
          <ErrorTile
            key={`${m.value}-${m.detail}`}
            value={m.value}
            detail={m.detail}
            setNbErrors={setNbErrors}
          />
        ))}
      </>
    ));
  }

  useEffect(() => {
    setNbErrors(context.errors.exceptions.length);
    setErrorBlocks(renderCategories(context.errors.exceptions));
  }, [context.errors]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDTypography variant="h2" fontWeight="medium">
            Almost there
          </MDTypography>
          {nbErrors > 0 ? (
            <MDTypography variant="h4" fontWeight="light">
              There are <strong>{nbErrors}</strong> modifications to make on the file
            </MDTypography>
          ) : (
            <MDTypography variant="h4" fontWeight="light">
              Once all modifications are made, you may upload your file again
            </MDTypography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ padding: "2rem" }}>
            {errorBlocks}
            <MDBox display="flex" p={3} gap={3}>
              <UploadButton
                text="Upload corrected version"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                maxSize={100}
                onLoad={context.handleUploadClick}
              />
              <DownloadButton
                variant="outlined"
                text="Download a new template"
                download={context.handleDownloadClick}
              />
              <LinkButton
                variant="outlined"
                color="error"
                text="Back to project"
                to={`/projects/${context.projectData.id}`}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorsScreen;
