import VolcanoPlot from "../Charts/VolcanoPlot";
import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Box, Card, IconButton, Skeleton, Tab, Tabs } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import APIService from "services/DjangoAPI";
import MAPlot from "../Charts/MAPlot";
import { TabContext, TabPanel } from "@mui/lab";
import toolTipsTexts from "assets/toolTipsTexts";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";

function DEGPlots(props) {
  const [loading, setLoading] = useState(true);
  const { genolistId, comparison, genolistName } = props;
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState("1");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await APIService.getVolcanoAndMAPlot(genolistId);
        setData(data);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MDBox mb={3}>
      <Card
        className="chart-container"
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <MDBox pb={2}>
          <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
            <MDTypography variant="h5" fontWeight="medium">
              DEG Plots
            </MDTypography>
            <CustomTooltip
              width={400}
              title={tabValue == 1 ? toolTipsTexts.volcanoPlot : toolTipsTexts.MAPlot}
            >
              <IconButton sx={{ py: 0, pr: 3 }}>
                <HelpIcon />
              </IconButton>
            </CustomTooltip>
          </MDBox>
        </MDBox>

        <TabContext value={tabValue}>
          <Box pl={3} pr={3}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="project tabs">
              <Tab label="Volcano plot" value="1" />
              <Tab label="MA plot" value="2" />
            </Tabs>
          </Box>

          {!loading ? (
            <div>
              <TabPanel value="1" sx={{ pt: 2, pb: 0, pl: 0, pr: 1 }}>
                <VolcanoPlot data={data} comparison={comparison} genolistName={genolistName} />
              </TabPanel>
              <TabPanel value="2" sx={{ pt: 2, pb: 0, pl: 0, pr: 1 }}>
                <MAPlot data={data} comparison={comparison} genolistName={genolistName} />
              </TabPanel>
            </div>
          ) : (
            <Skeleton variant="rectangular">
              <Plot />
            </Skeleton>
          )}
        </TabContext>
      </Card>
    </MDBox>
  );
}

export default DEGPlots;
