import DataTable from "examples/Tables/DataTable";
import ListCell from "sciliciumLayouts/components/Tables/ListCell";
import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";

import React from "react";

class ListGenolists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // Initialize loading state to true
      data: [], // This will hold your data once it's loaded
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const tableRows = [];

    this.props.data.map((element) => {
      const format_row = {
        name_description: (
          <ListCell
            name={element.name}
            description={element.description}
            id={element.id}
            type="genolist"
          />
        ),
        project: (
          <ListCell name={element.project.name} id={element.project.id} type="project"></ListCell>
        ),
      };
      tableRows.push(format_row);
    });
    this.setState({ rows: tableRows, loading: false });
  };

  render() {
    const { loading, rows } = this.state;
    const dataTop = {
      columns: [
        { Header: "name and description", accessor: "name_description", width: "65%" },
        { Header: "project", accessor: "project", align: "center" },
      ],
      rows: rows,
    };

    if (loading) {
      return <TableSkeleton rows={10} cols={2} />; // Adjust the number of rows and columns to match your table
    }

    return (
      <DataTable
        table={dataTop}
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
      />
    );
  }
}

export default ListGenolists;
