import { Button, Card, Col, Form, Input, message, Row, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useState, useEffect } from "react";
import axiosInstance from "axiosApi";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { getAuthenticatedUserId } from "tokenHelper";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null); // Store the user data
  const [error, setError] = useState("");
  const { user, isLoading, setUser } = useAuthContext();
  const { id } = useParams();

  const authenticatedUserId = getAuthenticatedUserId();
  const isCurrentUserProfile = authenticatedUserId === id;

  useEffect(() => {
    setLoading(true);
    const endpoint = isCurrentUserProfile ? "/users/me" : `/users/${id}`;

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setUserData(response.data); // Store the user data in state
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Error fetching user data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const handleProfileUpdate = async (data) => {
    setLoading(true);
    try {
      const userId = isCurrentUserProfile ? authenticatedUserId : id;

      const response = await axiosInstance.put(`/users/${userId}`, data);
      const responseData = response.data;

      setUser(responseData);
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(Error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (!userData) {
    // Handle the case where the user data for the given id is not available
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div>{error || "User data not found."}</div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card className="profile_page_card">
          <Form
            layout="vertical"
            initialValues={{
              username: userData.username,
              email: userData.email,
              first_name: userData.first_name,
              last_name: userData.last_name,
            }}
            onFinish={handleProfileUpdate}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required!",
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="First Name" disabled={!isCurrentUserProfile} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required!",
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" disabled={!isCurrentUserProfile} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Username is required!",
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Username" disabled={!isCurrentUserProfile} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email is required!",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Email" disabled={!isCurrentUserProfile} />
                </Form.Item>
              </Col>
            </Row>
            {isCurrentUserProfile && ( // Render the Save button only for the authenticated user's profile
              <Button className="profile_save_btn" htmlType="submit" type="primary" size="large">
                {loading ? (
                  <>
                    <Spin size="small" /> Saving
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            )}
          </Form>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Profile;
