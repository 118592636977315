import { useEffect, useState } from "react";
import { Card, IconButton, Skeleton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Plot from "react-plotly.js";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import toolTipsTexts from "assets/toolTipsTexts";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";

function EnrichmentDotPlot({ topEnrichmentData, loading, setMaxHeight }) {
  const [traces, setTraces] = useState([]);
  const [layout, setLayout] = useState({});
  const customColorScale = [
    [0, colors.info.main], // Color at the lowest end of the scale
    [1, colors.error.main], // Color at the highest end of the scale
  ];

  useEffect(() => {
    if (!loading && topEnrichmentData && topEnrichmentData.length > 0) {
      //Find the min and max count
      const minCount = Math.min(...topEnrichmentData.map((item) => item.count));
      const maxCount = Math.max(...topEnrichmentData.map((item) => item.count));
      const step = (maxCount - minCount) / 4;

      const legendSizes = [
        minCount,
        minCount + step,
        minCount + 2 * step,
        minCount + 3 * step,
        maxCount,
      ];

      //   // Create custom size legend traces
      //   const sizeLegendTraces = legendSizes.map((size) => ({
      //     x: [null], // No X value
      //     y: [null], // No Y value
      //     mode: "markers",
      //     marker: {
      //       size: size,
      //       color: "rgba(0,0,0,0)", // Make the color transparent
      //     },
      //     name: `${size.toFixed(0)} count`, // Legend text
      //     showlegend: true,
      //     type: "scatter",
      //   }));

      const traces = [
        {
          x: topEnrichmentData.map((item) => item.count / item.nb_genes_in_term),
          y: topEnrichmentData
            .map((item) => {
              // Truncate description if it's too long
              const maxLength = 28;
              let truncatedDescription = item.goref.description;
              if (truncatedDescription === null) {
                truncatedDescription = item.goref.term;
              };
              if (truncatedDescription.length > maxLength) {
                truncatedDescription = truncatedDescription.substring(0, maxLength) + "...";
              } 
              return truncatedDescription;
            })
            .slice()
            .reverse(),
          mode: "markers",
          marker: {
            size: topEnrichmentData.map((item) => item.count),
            color: topEnrichmentData.map((item) => item.pvalue_adj),
            colorscale: customColorScale,
            colorbar: {
              title: `adjusted <br> p-value`, // Title for the color scale
              titleside: "top",
              xpad: 10,
              ypad: 25,
            },
            showscale: true, // Show color scale
          },
          type: "scatter",
        },
      ];

      // Define the layout
      const layout = {
        xaxis: {
          title: "Gene ratio (count / nb of genes in term)",
          type: "linear",
        },
        yaxis: {
          title: "",
          automargin: false, // Adjust margin automatically
        },
        margin: { t: 20, l: 180 }, // Adjust top margin
        hovermode: "closest",
      };

      setTraces(traces);
      setLayout(layout);
    }
  }, [loading, topEnrichmentData]);

  const handleAfterPlot = () => {
    // Use a timeout to ensure the DOM has updated
    setTimeout(() => {
      const plotDiv = document.getElementById("enrichmentPlot");
      if (plotDiv) {
        setMaxHeight(plotDiv.clientHeight);
      }
    }, 0);
  };

  return (
    <Card>
      <MDBox pb={3}>
        <MDBox pb={2}>
          <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
            <MDTypography variant="h5" fontWeight="medium">
              GO Enrichment Dot Plot
            </MDTypography>
            <CustomTooltip title={toolTipsTexts.enrichmentDotPlot}>
              <IconButton sx={{ py: 0, pr: 3 }}>
                <HelpIcon />
              </IconButton>
            </CustomTooltip>
          </MDBox>
        </MDBox>
        {loading ? (
          <Skeleton>
            <Plot />
          </Skeleton>
        ) : (
          <Plot
            data={traces}
            layout={layout}
            style={{ width: "100%", height: "100%" }}
            useResizeHand={true}
            onAfterPlot={handleAfterPlot}
            divId="enrichmentPlot"
          />
        )}
      </MDBox>
    </Card>
  );
}

export default EnrichmentDotPlot;
