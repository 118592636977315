import axios from 'axios';

const API_REST_URL = "https://strapi.scilicium.com/";
const ENRICH_API_URL = "http://localhost:8000/"

class APIService {
    
    getChemicals(){
        return axios.get(API_REST_URL+'chemicals');
    }
    getChemical(id){
        return axios.get(API_REST_URL+'chemicals/'+id);
    }
    getChemicalsNumber(){
        return axios.get(API_REST_URL+'chemicals/count');
    }

    getExperimentals(){
        return axios.get(API_REST_URL+'experimental-conditions');
    }
    getExperimental(id){
        return axios.get(API_REST_URL+'experimental-conditions/'+id);
    }
    getExperimentalsNumber(){
        return axios.get(API_REST_URL+'experimental-conditions/count');
    }

    getEnrich(genes,type,species){
        return axios.get(ENRICH_API_URL+'enrichment?genes='+genes+'&species='+species+'&type='+type);
    }

}

export default new APIService();