import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTableCustom from "../DataTableCustom";
import APIService from "services/DjangoAPI";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";
import { GENECARD_DATABASE } from "constants";
import colors from "assets/theme/base/colors";
import MDButton from "../../../../components/MDButton";
import DownloadCSVButton from "../../Buttons/DownloadCSVButton";

function ListGenes({ genolistId, typeGenolist, setListOfGenes, genolistName, projectName }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        // Retrieve the values associated with the genolistId and the genorefs attached to each value, depending on the type of genolist
        let data;
        const fetchData = async () => {
          switch (typeGenolist) {
            case "TRANSCRIPTO":
              ({ data } = await APIService.getTranscriptoValuesByGenolist(genolistId));
              break;
            case "PROTEO":
              ({ data } = await APIService.getProteoValuesByGenolist(genolistId));
              break;
          }
        };
        await fetchData();

        const genorefs = [];
        const tableRows = [];

        //Iterate through each TranscriptoValue or ProteoValue to prepare the data for the table and to retrieve the genorefs
        for (const value of data) {
          tableRows.push({
            id: value.id,
            geneSymbol: value.genoref.symbols,
            geneId: value.genoref.id_reference_database,
            baseMean: value.base_mean,
            log2FC: value.log_2_FC,
            pValueAdj: value.pvalue_adj,
          });
          genorefs.push(value.genoref);
        }
        setRows(tableRows);
        setListOfGenes(genorefs);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [genolistId]);

  const columns = [
    {
      headerName: "Gene Symbol",
      field: "geneSymbol",
      align: "center",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        //Render the cell content as a link to the GO term page
        return (
          <Link
            to={GENECARD_DATABASE + params.value}
            target="_blank"
            rel="noreferrer"
            style={{ color: colors.dark.main }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      headerName: "Gene Id",
      field: "geneId",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: "Base Mean",
      field: "baseMean",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
      renderCell: (params) => {
        // If the first significant number is within the first three decimals, then we display it with three decimals, including the trailing zeros
        // Otherwise, we display it with scientific notation
        const absPValue = Math.abs(params.value);

        if (0.001 <= absPValue && absPValue < 1000) {
          // Format as a fixed number with three decimal places
          return parseFloat(params.value).toFixed(3);
        } else {
          // Format in scientific notation
          return parseFloat(params.value).toExponential(3);
        }
      },
    },
    {
      headerName: "log2FC",
      field: "log2FC",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
      renderCell: (params) => {
        // If the first significant number is within the first three decimals, then we display it with three decimals, including the trailing zeros
        // Otherwise, we display it with scientific notation
        const absPValue = Math.abs(params.value);

        if (0.001 <= absPValue && absPValue < 1000) {
          // Format as a fixed number with three decimal places
          return parseFloat(params.value).toFixed(3);
        } else {
          // Format in scientific notation
          return parseFloat(params.value).toExponential(3);
        }
      },
    },
    {
      headerName: "pValue adj",
      field: "pValueAdj",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
      renderCell: (params) => {
        // If the first significant number is within the first three decimals, then we display it with three decimals, including the trailing zeros
        // Otherwise, we display it with scientific notation
        const absPValue = Math.abs(params.value);

        if (0.001 <= absPValue && absPValue < 1000) {
          // Format as a fixed number with three decimal places
          return parseFloat(params.value).toFixed(3);
        } else {
          // Format in scientific notation
          return parseFloat(params.value).toExponential(3);
        }
      },
    },
  ];

  return (
    <Card>
      <MDBox pb={2}>
        <MDBox pl={3} pt={3} pr={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            List of genes
          </MDTypography>
          <DownloadCSVButton genolistId={genolistId} genolistName={genolistName} projectName={projectName}/>
        </MDBox>
      </MDBox>
      {loading ? (
        <TableSkeleton rows={10} cols={5} />
      ) : (
        <DataTableCustom rows={rows} columns={columns} pageSizeOptions={[10, 25, 50, 100]} />
      )}
    </Card>
  );
}

export default ListGenes;
