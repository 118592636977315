import { useState } from "react";
import { Grid, Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SequenceDistribution from "./SequenceDistribution";
import GenesDetected from "./GenesDetected";
import PCAPlot from "./PCA";
import SamplesCorrelation from "./SamplesCorrelation";
import SamplesQualityControl from "./SamplesQualityControl";

function ResultsOverviewTile(props) {
  const [loading, setLoading] = useState(true);
  const [projectAnalysisData, setProjectAnalysisData] = useState([]);

  return (
    <MDBox mb={3}>
      {props.projectStatus !== "SUCCESS" ? (
        <Card>
          <MDBox
            height={200}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <MDTypography variant="h5">No results yet</MDTypography>
            <MDTypography mt={1} variant="body2" align="center">
              Results related to this project will be featured here
              <br />
              (Samples quality control, sequence distribution and count of genes detected per
              sample, PCA plot, samples correlation)
            </MDTypography>
          </MDBox>
        </Card>
      ) : (
        <Grid container spacing={3}>
          <Grid item md={12} xl={12}>
            <SamplesQualityControl projectId={props.projectId} />
          </Grid>
          <Grid item md={12} xl={6}>
            <SequenceDistribution projectId={props.projectId} />
          </Grid>
          <Grid item md={12} xl={6}>
            <GenesDetected projectId={props.projectId} />
          </Grid>
          <Grid item md={12} xl={6}>
            <PCAPlot projectId={props.projectId} />
          </Grid>
          <Grid item md={12} xl={6}>
            <SamplesCorrelation projectId={props.projectId} />
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
}

export default ResultsOverviewTile;
