// UI & Styling
import PropTypes from "prop-types";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DoneIcon from "@mui/icons-material/Done";
import { SciliciumStepIconRoot } from "sciliciumLayouts/components/SciliciumStepper";

export function UploadStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <DownloadIcon />,
    2: <EditIcon />,
    3: <FileUploadIcon />,
    4: <DoneIcon />,
  };

  return (
    <SciliciumStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? icons[4] : icons[String(props.icon)]}
    </SciliciumStepIconRoot>
  );
}

UploadStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};