// Hooks
import { useRef, useState } from "react";

// UI Components
import MDButton from "components/MDButton";
import UploadIcon from "@mui/icons-material/Upload";
import { Popover, Typography } from "@mui/material";

// Styling
import colors from "assets/theme/base/colors";

/**
 * A button to upload a file.
 * 
 * This component has local file validations parameters:
 * - accept: Validates the upload MIME type.
 * - maxSize: Blocks uploads superior than the maxSize value (in kilobytes).
 * 
 * If file is valid, the component will call onLoad, which must be a
 * callback function.
 */
const UploadButton = ({ variant, text, onLoad, accept, maxSize }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState(null);

  const handlePopoverOpen = (event) => {
    setTimeout(() => handlePopoverClose(), 2000)
  };

  const handlePopoverClose = () => {
    setError(null);
  };

  const open = Boolean(error);

  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // File type validation
    if (accept && file.type !== accept) {
      setError("This file type is not accepted");
      handlePopoverOpen(event);
      return;
    }

    // File size validation
    if (maxSize > 0 && file.size > maxSize*1024) {
      setError("This file is too big");
      handlePopoverOpen(event);
      return;
    }

    onLoad(file);
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <MDButton
        color="primary"
        variant={variant || "gradient"}
        startIcon={<UploadIcon />}
        onClick={handleButtonClick}
      >
        {text || "Choose a file"}
      </MDButton>
      <Popover
        sx={{
          pointerEvents: "none",
          ".MuiPopover-paper": { backgroundColor: colors.error.main },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography variant="subtitle2" sx={{ px: 1, color: colors.light.main, fontSize:"1rem" }}>
          {error}
        </Typography>
      </Popover>
    </>
  );
};

export default UploadButton;
