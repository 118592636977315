import React from "react";

const Iframe = ({ srcUrl }) => {
  return (
    <div>
      <iframe src={srcUrl} width="100%" height="500px" frameBorder="0"></iframe>
    </div>
  );
};

export default Iframe;