/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
  11. The `private` key is used to store the private route.
*/

/**
 * Custom keys:
 * - hideInSidebar: if true, the route will not be displayed in the sidebar
 */

import ToxicogenomicsDashboard from "sciliciumLayouts/pages/toxicogenomics";
import ExpCondView from "sciliciumLayouts/pages/ExpCondView";
import Genolist from "sciliciumLayouts/pages/genolist";
import SciliciumDashboard from "sciliciumLayouts/pages/sciliciumDashboard";

import Kanban from "layouts/applications/kanban";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import SignIn from "sciliciumLayouts/pages/SignIn/SignIn";
import SignUp from "sciliciumLayouts/pages/SignUp/SignUp";
import Profile from "sciliciumLayouts/pages/Profile/Profile";

// @mui icons
import Icon from "@mui/material/Icon";
import ListElements from "sciliciumLayouts/pages/ListElements";
import Project from "sciliciumLayouts/pages/Project";
import NewProject from "sciliciumLayouts/pages/NewProject";
import Sample from "sciliciumLayouts/pages/Sample";
import ProjectUpload from "sciliciumLayouts/pages/ProjectUpload";
import FAQ from "sciliciumLayouts/pages/FAQ";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <SciliciumDashboard />,
    noCollapse: true,
    private: true,
    hideInSidebar: false,
  },
  {
    name: "expcondition",
    key: "expcondition",
    route: "/expcondition/:cid",
    component: <ExpCondView />,
    noCollapse: true,
    hideInSidebar: false,
  },
  {
    name: "login",
    key: "login",
    route: "/login",
    component: <SignIn />,
    noCollapse: true,
    hideInSidebar: false,
  },
  {
    name: "register",
    key: "register",
    route: "/register/",
    component: <SignUp />,
    noCollapse: true,
    hideInSidebar: false,
  },
  {
    name: "profile",
    key: "profile",
    route: "/profile/:id",
    component: <Profile />,
    noCollapse: true,
    private: true,
    hideInSidebar: false,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <Icon fontSize="medium">folder_copy</Icon>,
    hideInSidebar: false,
    collapse: [
      {
        name: "My projects",
        key: "myprojects",
        route: "/projects/myprojects",
        component: <ListElements typePage="myprojects" />,
        hideInSidebar: false,
      },
      {
        name: "Public projects",
        key: "public-projects",
        route: "/projects/public",
        component: <ListElements typePage="publicprojects" />,
        hideInSidebar: false,
      },
      {
        name: "New project",
        key: "new-project",
        route: "/projects/new",
        component: <NewProject />,
        hideInSidebar: false,
      },
      {
        name: "Project's details",
        key: "projects-details",
        route: "/projects/:id",
        component: <Project />,
        hideInSidebar: true,
      },
      {
        name: "Upload description sheet",
        key: "projects-upload",
        route: "/projects/:id/upload",
        component: <ProjectUpload />,
        hideInSidebar: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Toxicogenomics",
    key: "toxicogenomics",
    icon: <Icon fontSize="medium">science</Icon>,
    route: "/toxicogenomics",
    component: <ToxicogenomicsDashboard />,
    noCollapse: true,
    hideInSidebar: true,
  },
  {
    type: "collapse",
    name: "Dash (V2)",
    key: "dash",
    icon: <Icon fontSize="medium">space_dashboard</Icon>,
    hideInSidebar: false,
    collapse: [
      {
        name: "My Dash",
        key: "mydash",
        route: "/dash/mydash",
        component: <Kanban />,
        icon: <Icon fontSize="medium">list</Icon>,
        hideInSidebar: false,
      },
      {
        name: "New Dash",
        key: "new-dash",
        route: "/dash/new",
        component: <DataTables />,
        icon: <Icon fontSize="medium">add_circle</Icon>,
        hideInSidebar: false,
      },
      {
        name: "Public Dash",
        key: "public-dash",
        route: "/dash/public",
        component: <Calendar />,
        icon: <Icon fontSize="medium">public</Icon>,
        hideInSidebar: false,
      },
    ],
  },
  {
    type: "collapse",
    name: "Genolists",
    key: "genolists",
    icon: <Icon fontSize="medium">list_alt</Icon>,
    hideInSidebar: false,
    collapse: [
      {
        name: "My genolists",
        key: "mygenolists",
        route: "/genolists/mygenolists",
        component: <ListElements typePage="mygenolists" />,
        hideInSidebar: false,
      },
      {
        name: "New genolist",
        key: "new-genolist",
        route: "/genolists/new",
        hideInSidebar: false,
      },
      {
        name: "Public genolists",
        key: "public-genolist",
        route: "/genolists/public",
        component: <ListElements typePage="publicgenolists" />,
        hideInSidebar: false,
      },
      {
        name: "Genolist's details",
        key: "genolist-details",
        route: "/genolists/:id",
        component: <Genolist />,
        hideInSidebar: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "FAQ",
    key: "FAQ",
    icon: <Icon fontSize="medium">help</Icon>,
    route: "/faq",
    component: <FAQ />,
    noCollapse: true,
    private: true,
    hideInSidebar: false,
  },
  {
    type: "collapse",
    name: "Sample's details",
    key: "sample-details",
    route: "/samples/:id",
    component: <Sample />,
    noCollapse: true,
    hideInSidebar: true,
  },
];

export default routes;
