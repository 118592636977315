import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

// This higher-order component will create a custom styled Tooltip with a given width.
const createStyledTooltip = (width) => {
  return styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: width,
    },
  });
};

const CustomTooltip = (props) => {
  const { width, ...otherProps } = props;
  const StyledTooltip = createStyledTooltip(width);
  return <StyledTooltip {...otherProps} />;
};

export default CustomTooltip;
