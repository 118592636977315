/**
=========================================================
* SciLicium genoApp - v0.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Author: Thomas Darde
Coded by www.creative-tim.com
Modified by SciLicium
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import Footer from "examples/Footer";

// SciLicium Components
import CondTable from 'sciliciumLayouts/components/Tables/Condition';
import ScAutocomplete from 'sciliciumLayouts/components/Autocomplete';
import EnrichTable from 'sciliciumLayouts/components/Tables/EnrichmentTable';
import EnrichKEGG from 'sciliciumLayouts/components/Tables/EnrichKEGGTable';
import ScatterPlot from 'sciliciumLayouts/components/Charts/Scatterplot';


// Loader
import PacmanLoader from "react-spinners/PacmanLoader";

// API service
import APIService from "services/APIService"


function ExpCondView() {
    const {cid} = useParams(); //get the URL parameters
    const [expcond, setExpcond] = useState({});
    const [structure, setStructure] = useState({});
    const [loading, setLoading] = useState(true);
    const [expression, setExpression] = useState({});
    const [deg, setDEG] = useState(0);
    const [kegg, setKegg] = useState(0);
    const [go, setGO] = useState(0);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                APIService.getExperimental(cid).then((data) => {
                    setExpcond(data.data);
                    setExpression(data.data.expression);
                    setStructure("https://strapi.scilicium.com"+data.data.chemical.structure[0].url);
                    setDEG(data.data.enrichment.overview.genes_number);
                    setKegg(data.data.enrichment.overview.kegg_number);
                    setGO(data.data.enrichment.overview.go_number);
                    setLoading(false);
                })
                
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }, []);
    console.log(expcond)
        return (
            <DashboardLayout>
            <DashboardNavbar />
            {loading?
                <PacmanLoader
                        loading={loading}
                        size={50}
                        color="#36d7b7"
                        aria-label="Loading Spinner"
                        data-testid="loader"
                />:
                
                <MDBox py={3}>
                    <MDBox mb={3}>
                        <Grid container spacing={3} alignItems="center" >
                            <Grid item xs={12} sm={12} lg={12} >
                                <Card>
                                    <MDTypography ml={3} mb={3} mt={3} variant="h3" fontWeight="medium">
                                            {expcond.name}
                                    </MDTypography>
                                </Card> 
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <DefaultInfoCard
                                    icon="science"
                                    title="Number of DEG"
                                    value={deg}
                                    color="primary"
                                />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <DefaultInfoCard
                                icon="biotech"
                                title="GO enrichment"
                                value={go}
                                color="info"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <DefaultInfoCard
                                icon="biotech"
                                title="KEGG enrichment"
                                value={kegg}
                                color="info"
                            />
                        </Grid>
                    </Grid>
                    </MDBox>
                    
                    <MDBox mb={3}>
                        <Card>
                            <Grid container spacing={1} alignItems="center" >
                                <Grid item xs={12} sm={4} lg={4} >
                                        <MDBox
                                            component="img"
                                            src={structure}
                                            borderRadius="lg"
                                            shadow="none"
                                            width="50%"
                                            height="100%"
                                            position="relative"
                                            zIndex={1}
                                            style={{padding:'20px'}}
                                        />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={8}>
                                        <MDBox pt={3} px={3}>
                                            <MDTypography variant="h6" fontWeight="medium">
                                                Chemicals
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox py={1}>
                                            <CondTable data={expcond}/>
                                        </MDBox>
                                </Grid>
                            </Grid>
                        </Card>
                    </MDBox>
                    <MDBox mb={3}>
                        <Grid container spacing={3} alignItems="center" >
                            <Grid item xs={12} sm={12} lg={12}  mb={3} mt={3} >
                                <Card  mb={3} mt={3}>
                                    <MDBox pt={3} px={3}>
                                        <MDTypography variant="h6" fontWeight="medium">
                                            Gene count
                                        </MDTypography>
                                    </MDBox>
                                    <ScAutocomplete expression={expression} />
                                </Card> 
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mb={3}>
                        <Grid container spacing={3} alignItems="center" >
                            <Grid item xs={12} sm={12} lg={12}  mb={3} mt={3} >
                                <Card  mb={3} mt={3}>
                                    <MDBox pt={3} px={3}>
                                        <MDTypography variant="h6" fontWeight="medium">
                                            DEG Fold change
                                        </MDTypography>
                                    </MDBox>
                                    <ScatterPlot data={expcond}/>
                                </Card> 
                            </Grid>
                        </Grid>
                    </MDBox>
                    {go > 0 ? 
                    <MDBox mb={3}>
                        <Grid container spacing={3} alignItems="center" >
                            <Grid item xs={12} sm={12} lg={12}  mb={3} mt={3} >
                                <Card  mb={3} mt={3}>
                                    <MDBox pt={3} px={3}>
                                        <MDTypography variant="h6" fontWeight="medium">
                                            GO enrichment
                                        </MDTypography>
                                    </MDBox>
                                    <EnrichTable dataexp={expcond}/>
                                </Card> 
                            </Grid>
                        </Grid>
                    </MDBox>:
                    <MDBox mb={3}></MDBox>
                    }
                    {kegg > 0 ? 
                        <MDBox mb={3}>
                        
                        <Grid container spacing={3} alignItems="center" >
                            <Grid item xs={12} sm={12} lg={12}  mb={3} mt={3} >
                                <Card  mb={3} mt={3}>
                                    <MDBox pt={3} px={3}>
                                        <MDTypography variant="h6" fontWeight="medium">
                                            KEGG enrichment
                                        </MDTypography>
                                    </MDBox>
                                    <EnrichKEGG dataexp={expcond}/>
                                </Card> 
                            </Grid>
                        </Grid>
                    </MDBox>:
                    <MDBox mb={3}></MDBox>
                    }
                </MDBox>
            }
            <Footer />
            </DashboardLayout>
        );
};

export default ExpCondView;

