// Hooks
import { useState } from "react";

// UI Components
import MDButton from "components/MDButton";
import DownloadIcon from "@mui/icons-material/Download";

/**
 * A button to download files.
 * The download prop must be a callback returning a blob file
 */
const DownloadButton = ({ variant, color, text, disabled, download }) => {

  const [downloading, setDownloading] = useState(false);

  /**
   * Calls the download function and downloads the file returned
   */
  const handleClick = async () => {
    setDownloading(true);
    const file = await download();
    const url = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = "DownloadedFile.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  return (
    <MDButton
      color={color || "primary"}
      variant={variant || "gradient"}
      disabled={downloading || disabled}
      startIcon={<DownloadIcon />}
      onClick={handleClick}
    >
      {text || "Download"}
    </MDButton>
  );
};

export default DownloadButton;
