/*
This component was coded using a great part of code issued by Creative Tim in this template : 

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

*/
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "sciliciumLayouts/components/Footer";

// Loader
import PacmanLoader from "react-spinners/PacmanLoader";

import APIService from "services/DjangoAPI";
import InfoSample from "sciliciumLayouts/components/InfoSample";

function Sample() {
  const { id } = useParams();
  const [sample, setSample] = useState("");
  const [loading, setLoading] = useState("");

  const fetchSample = async () => {
    setLoading(true);
    try {
      const { data } = await APIService.getSample(id);
      console.log("sample", data);
      setSample(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(async () => {
    await fetchSample();
    setLoading(false);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <PacmanLoader
          loading={loading}
          size={50}
          color="#36d7b7"
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <MDBox py={3}>
          <MDBox mb={3}>
            <Card sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <MDTypography variant="h4" fontWeight="medium">
                  Project {sample.project?.name}: sample {sample.name}
                </MDTypography>
                {sample["comment"] !== null || sample["comment"] !== "" ? (
                  <MDTypography variant="h6" color="dark">
                    Comment: {sample.comment}
                  </MDTypography>
                ) : (
                  <div></div>
                )}
              </MDBox>
            </Card>
          </MDBox>
          {sample["factors"] !== undefined ? (
            <div>
              <InfoSample factors={sample.factors} species={sample.species} sampleId={sample.id} />
            </div>
          ) : (
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Summary
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        List of Genes
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1}>{/* <TopViews data={genolist.genorefs}/> */}</MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Sample;
