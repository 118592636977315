import "../resultsOverviewStyling.css";
import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import APIService from "services/DjangoAPI";
import { Card, IconButton, Skeleton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import toolTipsTexts from "assets/toolTipsTexts";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";

function SequenceDistribution(props) {
  const [loading, setLoading] = useState(true);
  const [plotData, setPlotData] = useState([]);
  const [layout, setLayout] = useState({});
  const projectId = props.projectId;
  const customLabels = {
    umi_assigned: "Reads after demultiplexing and QC",
    umi_aligned: "Reads aligned to the reference transcriptome",
    refseq_total: "Reads with a good alignement to the nuclear transcriptome",
    refseq_umi: "Unique nuclear transcripts",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your actual data fetching method
        const { data } = await APIService.getSequenceDistributionByProject(projectId);

        // Define an array of colors for your traces
        const myColors = [colors.success.main, colors.info.main, "#7fdfea", "#1a5449"];

        setPlotData(
          data.map((category, index) => ({
            y: category.data.map((entry) => entry.sample_name).reverse(),
            x: category.data.map((entry) => entry.value),
            name: customLabels[category.name] || category.name,
            type: "bar",
            orientation: "h",
            marker: {
              color: myColors[index % myColors.length], // Cycle through your colors array
            },
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("error", error);
      }
    };

    setLayout({
      barmode: "group",
      title: {
        y: 1, // Adjust the title position (0 to 1, top to bottom)
        x: 0.5, // Center the title
        xanchor: "center",
        yanchor: "top",
      },
      yaxis: { title: "Samples", automargin: true },
      xaxis: {
        title: {
          text: "Count",
          standoff: 10, // Distance between the tick labels and the title
        },
        automargin: true,
        side: "top",
      },
      margin: { l: 50, r: 50, b: 50, t: 170, pad: 4 },
      showlegend: true,
      height: null, // We set the width dynamically based on the number of samples
      width: "100%",
      legend: {
        orientation: "h",
        xanchor: "center",
        yanchor: "top",
        y: 1.035,
        x: 0.4,
      },
    });

    fetchData();
  }, [projectId]);

  useEffect(() => {
    // Once we have the plot data, calculate the height based on number of samples
    if (plotData.length > 0) {
      const numSamples = plotData[0].y.length;
      const heightPerSample = 50; // Height per sample, adjust as needed
      const totalHeight = numSamples * heightPerSample;

      setLayout((prevLayout) => ({
        ...prevLayout,
        height: totalHeight,
      }));
    }
  }, [plotData]);

  // if (loading) {
  //   return <div>Loading...</div>; // Or a spinner/loading component
  // }

  return (
    <Card>
      <MDBox pb={2} pr={2} pl={2}>
        <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Sequence Distribution
          </MDTypography>
          <CustomTooltip width={400} title={toolTipsTexts.sequenceDistribution}>
            <IconButton sx={{ py: 0, pr: 3 }}>
              <HelpIcon />
            </IconButton>
          </CustomTooltip>
        </MDBox>
      </MDBox>
      <MDBox pb={1} pr={1} pl={1}>
        <div className="custom-plot-container">
          {loading ? (
            <Skeleton>
              <Plot />
            </Skeleton>
          ) : (
            <Plot
              data={plotData}
              layout={layout}
              style={{ width: "100%", height: "100%" }}
              className="custom-plot"
            />
          )}
        </div>
      </MDBox>
    </Card>
  );
}

export default SequenceDistribution;
