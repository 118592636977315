/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components
import TopViewsCell from "sciliciumLayouts/components/TopViewsCell";
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";
import DataTable from "examples/Tables/DataTable";

import React from 'react';
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

class TopViews extends React.Component {

getData = () => {
  const tableRows = [];

  this.props.data.map((element) => {
    const format_row = {
      name: <TopViewsCell name={element.name} views={element.views} />,
      owner: <DefaultCell>{element.owner}</DefaultCell>,
      link: 
        <Link to="https://www.scilicium.com">
          <MDButton color="primary" variant="gradient">View</MDButton>
        </Link>
    }
    tableRows.push(format_row);
  })
  return tableRows;
}


render() {
 
  const dataTop = {
    columns: [
      { Header: "name", accessor: "name", width: "55%" },
      { Header: "owner", accessor: "owner", align: "center" },
      { Header: "link", accessor: "link", align: "center" },
    ],
    rows: [],
  }
  dataTop.rows = this.getData();

  return (
    <DataTable
      table={dataTop}
      entriesPerPage={false}
      showTotalEntries={false}
      isSorted={false}
      noEndBorder
    />

  );
}

}

export default TopViews;
