import React, { useEffect, useState } from "react";
import KeySelector from "./components/KeySelector";
import Iframe from "../Iframe";
import BoxPlot from "../Charts/Boxplot";
import BeatLoader from "react-spinners/BeatLoader";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

function ScAutocomplete({expression}) {
  const [selectedUrl, setSelectedUrl] = React.useState("");
  const [expressiondata, setExpression] = React.useState("");
  const [selectedexpression, setSelectedexpression] = React.useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    setExpression(expression.ctrl)
}, []);


  const handleKeyChange = (newValue) => {
    const compa_name = Object.keys(expression)[1];
    const expdata = {
      control : expression.ctrl[newValue] ,
      condition: expression[compa_name][newValue]
    }
    setSelectedexpression(expdata)
    setSelectedUrl("https://www.genecards.org/cgi-bin/carddisp.pl?gene="+newValue)
  };

  return (
    
    <div>
      {loading?
         <BeatLoader
          loading={loading}
          size={50}
          color="#36d7b7"
          aria-label="Loading Spinner"
          data-testid="loader"
        />:
      <MDBox py={3}>
        <Grid container spacing={1} alignItems="center" >
          <Grid item ml={3} xs={12} sm={6} lg={4}>
            <KeySelector json={expressiondata} onChange={handleKeyChange} />
          </Grid>
        </Grid>
        <MDBox mb={3}>
        <Grid container spacing={1} alignItems="center" >
          <Grid item  xs={12} md={6} lg={6}>
            <BoxPlot data={selectedexpression} title="Expression" ytitle="Number of count" xtitle=""/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Iframe srcUrl={selectedUrl} />
          </Grid>
        </Grid>
        </MDBox>
      </MDBox>
      }
      </div>
  );
}

export default ScAutocomplete