import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const dataTableData = {
  columns : [
    { Header: "name", accessor: "name"},
    { Header: "Description", accessor: "Description" },
    { Header: "Count", accessor: "Count" },
    { Header: "pval", accessor: "pval" },
    { Header: "padj", accessor: "padj" },
    { Header: "qval", accessor: "qval" },
    { Header: "GenesInQuery", accessor: "GenesInQuery" },
    { Header: "GenesInTermBackground", accessor: "GenesInTermBackground"},
  ],

  rows:[]
};



function EnrichKEGG({ dataexp }) {
  const classes = useStyles();

  if (dataexp.length === 0) {
    return <MDBox pt={3} px={3}><MDTypography variant="h6" fontWeight="medium">No data to display </MDTypography></MDBox>;
  }

  dataTableData.rows = dataexp.enrichment.kegg;

  return (
    <DataTable table={dataTableData} entriesPerPage={true} />
  );
}

export default EnrichKEGG;