/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// Material Dashboard 2 PRO React examples
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";

import Utils from "services/Utils";

class Timeline extends React.Component {

  constructor(props) { 
    super(props) 
    this.state = { 
      timelineData: [],
      loading: true,
      userId: props.userId,
      }
  }

  getData = () =>{
    return Utils.getTimelineDataByUser(this.state.userId);
  } 

  async componentDidMount() { 
    const data = await this.getData();
    this.setState({timelineData:data})
    this.setState({loading:false})
  }

  render() {
    
    const renderTimelineItems = this.state.timelineData.map(
      ({ color, icon, title, dateTime, description, lastItem }) => (
        <TimelineItem
          key={title + dateTime}
          color={color}
          icon={icon}
          title={title}
          dateTime={dateTime}
          description={description}
          lastItem={lastItem}
        />
      )
    );

  return (
    <div>
      {this.state.loading 
        ? <div>Retrieving data </div>
        : <TimelineList title="Your Timeline" nbNotif={this.state.timelineData.length}>{renderTimelineItems}</TimelineList>
      }
    </div>  
  );
  }
}

export default Timeline;
