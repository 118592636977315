import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Custom components
import Footer from "sciliciumLayouts/components/Footer";
import APIService from "services/DjangoAPI";
import ListGenolists from "./data/ListGenolists";
import ListProjects from "./data/ListProjects";

import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";

function ListElements(props) {
  const [loading, setLoading] = useState(true);
  const typePage = props.typePage;
  const [list, setList] = useState(null);
  const [element, setElement] = useState(null);

  const fetchMyGenolists = async () => {
    try {
      const response = await APIService.getMyGenolists();
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const fetchMyProjects = async () => {
    try {
      const response = await APIService.getMyProjects();
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const fetchPublicProjects = async () => {
    try {
      const response = await APIService.getPublicProjects();
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const fetchPublicGenolists = async () => {
    try {
      const response = await APIService.getPublicGenolists();
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  const getTitle = () => {
    switch (typePage) {
      case "mygenolists":
        return "My Genolists";
      case "publicgenolists":
        return "Public Genolists";
      case "myprojects":
        return "My Projects";
      case "publicprojects":
        return "Public Projects";
      default:
        return "Loading...";
    }
  };

  useEffect(() => {
    //To reset value when changing page
    setList(null);
    setElement(null);
    setLoading(true);

    const fetchData = async () => {
      try {
        switch (typePage) {
          case "mygenolists":
            const dataMyGenolists = await fetchMyGenolists();
            setList(dataMyGenolists);
            setElement(<ListGenolists data={dataMyGenolists} />);
            break;
          case "publicgenolists":
            const dataPublicGenolists = await fetchPublicGenolists();
            setList(dataPublicGenolists);
            setElement(<ListGenolists data={dataPublicGenolists} />);
            break;
          case "myprojects":
            const dataMyProjects = await fetchMyProjects();
            setList(dataMyProjects);
            setElement(<ListProjects data={dataMyProjects} />);
            break;
          case "publicprojects":
            const dataPublicProjects = await fetchPublicProjects();
            setList(dataPublicProjects);
            setElement(<ListProjects data={dataPublicProjects} />);
            break;
          default:
            console.log("default");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [typePage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h5" fontWeight="medium">
            {getTitle()}
          </MDTypography>
        </MDBox>
        {loading ? (
          <TableSkeleton rows={10} cols={4} title={false} twoLinedFirstCell={true} mt={1} />
        ) : (
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {element}
              </Grid>
            </Grid>
          </MDBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default ListElements;
