// Hooks
import { useState } from "react";

// UI components
import { IconButton, Popover, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

// Styling
import colors from "assets/theme/base/colors";

const InfoButton = ({ message }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        color="info"
        variant="contained"
      >
        <HelpIcon />
      </IconButton>
      <Popover
        sx={{
          pointerEvents: "none",
          ".MuiPopover-paper": { backgroundColor: colors.dark.main },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography maxWidth={400} variant="subtitle2" sx={{ px: 1, color: colors.light.main }}>
          {message}
        </Typography>
      </Popover>
    </>
  );
};
export default InfoButton;
