// Styling
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";

// UI components
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import StepContent, { stepContentClasses } from "@mui/material/StepContent";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { Stepper } from "@mui/material";

export const stepperStyle = {
  background: "rgb(255,255,255)",
  padding: "20px",
  boxShadow: boxShadows,
};
export const SciliciumStepper = styled(Stepper)(stepperStyle);

export const SciliciumStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.root}`]: {
    color: `${colors.primary.main}`,
  },
  [`& .${stepLabelClasses.label}`]: {
    color: `${colors.grey[400]}`,
    fontSize: "1.5rem",
    textTransform: "capitalize",
    margin: 0,
  },
  [`& .${stepLabelClasses.active}`]: {
    color: `${colors.primary.main} !important`,
  },
  [`& .${stepLabelClasses.completed}`]: {
    color: `${colors.success.main} !important`,
  },
}));

export const SciliciumStepContent = styled(StepContent)(({ theme }) => ({
  [`&.${stepContentClasses.root}`]: {
    borderLeft: `${colors.grey[500]} solid 2px !important`,
    marginLeft: "23px",
  },
}));

export const SciliciumConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      opacity: 1,
      borderLeft: `solid 2px ${colors.success.main}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      opacity: 1,
      borderLeft: `solid 2px ${colors.grey[500]}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    marginLeft: "17px",
    backgroundColor: "transparent",
    borderLeft: `solid 2px ${colors.grey[500]}`,
    borderRadius: 0,
    opacity: 1,
  },
}));

export const SciliciumStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: colors.gradients.primary.main,
    }),
    ...(ownerState.completed && {
      backgroundColor: colors.gradients.success.main,
    }),
  }));