import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function StatsGenolist(props) {
  const genolist = props.genolist;
  const derivationOperation = genolist.genolist_derivation_operation;
  const pipelineVersion = genolist.pipeline_version;
  const pValueCutoff = genolist.pvalue_cutoff;
  const foldChangeCutoff = genolist.fold_change_cutoff;

  return (
    <div>
      <Card>
        <MDBox ml={3} mt={3} pb={2}>
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              Statistical Information
            </MDTypography>
          </MDBox>
          <MDBox>
            {derivationOperation !== null ? (
              <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={1}>
                <MDTypography variant="h6" fontWeight="regular">
                  Derivation operation: {derivationOperation}
                </MDTypography>
              </MDBox>
            ) : (
              <div></div>
            )}
            {pipelineVersion !== null && (
              <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={1}>
                <MDTypography variant="h6" fontWeight="regular">
                  Pipeline version: {pipelineVersion}
                </MDTypography>
              </MDBox>
            )}
            <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={1}>
              <MDTypography variant="h6" fontWeight="regular">
                p value cutoff: {pValueCutoff}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={1}>
              <MDTypography variant="h6" fontWeight="regular">
                Fold Change cutoff: {foldChangeCutoff}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </div>
  );
}

export default StatsGenolist;
