//API
import APIService from "services/DjangoAPI";

class Utils {
  /**
   *
   * @param {Integer} id the id of the user being notified
   * returns an array of four objetcs as the four most recent notifications for the user
   */
  async getTimelineDataByUser(id) {
    const { data } = await APIService.getNotificationsByUser(id);
    return data.map((notif) => {
      //This is to initialize the format of a notification
      const dataToNotif = {
        color: "",
        icon: "",
        title: "",
        dateTime: "",
        description: "",
      };

      //Here, the notification is populated depending on the reason it was sent.
      switch (notif.reason) {
        case "NEW_QUOTE":
          dataToNotif.title = "You have a quote in waiting";
          dataToNotif.description =
            "We sent you a quote about your project " +
            notif.project.name +
            ", you can download it on the page of your project here. This quote is valid for one month. Thank you.";
          dataToNotif.color = "info";
          dataToNotif.icon = "notifications";
          break;
        case "NEW_MESSAGE":
          dataToNotif.title = "You have a new message";
          dataToNotif.description = "Please check your private messages !";
          dataToNotif.color = "info";
          dataToNotif.icon = "email";
          break;
        case "PROJECT_DENIED":
          dataToNotif.title = "Your project has been denied";
          dataToNotif.description =
            "We are very sorry to inform you  that your project " +
            notif.project.name +
            " was denied. You can find a detailed explanation on the page of your project.";
          dataToNotif.color = "warning";
          dataToNotif.icon = "do_not_disturb_on";
          break;
        case "DATA_TO_UPLOAD":
          dataToNotif.title = "Please upload your data";
          dataToNotif.description =
            "It's now time to upload your data regarding your project " +
            notif.project.name +
            " ! Please go on the upload page to find all needed details.";
          dataToNotif.color = "info2";
          dataToNotif.icon = "file_upload";
          break;
        case "DATA_UPLOADED":
          dataToNotif.title = "Your data was successfully uploaded";
          dataToNotif.description =
            "Your data was successfully uploaded regarding your project " +
            notif.project.name +
            " ! We are now waiting for your samples.";
          dataToNotif.color = "info2";
          dataToNotif.icon = "download_done";
          break;
        case "WAITING_SAMPLES":
          dataToNotif.title = "We are still waiting for your samples";
          dataToNotif.description =
            "Unless we are mistaken, to this day we have not received your samples regarding your project " +
            notif.project.name +
            ". Please contact us if you encounter any problem.";
          dataToNotif.color = "warning";
          dataToNotif.icon = "pending";
          break;
        case "SAMPLES_RECEIVED":
          dataToNotif.title = "We have received your samples";
          dataToNotif.description =
            "We are glad to inform you that we have received your samples regarding your project " +
            notif.project.name +
            ". The analysis will start soon.";
          dataToNotif.color = "info2";
          dataToNotif.icon = "done_all";
          break;
        case "ANALYSIS_READY":
          dataToNotif.title = "The results are available";
          dataToNotif.description =
            "Congratulations ! The analysis has ended and the results are available for your project " +
            notif.project.name +
            ". You will find them on your project's page.";
          dataToNotif.color = "success";
          dataToNotif.icon = "offline_pin";
          break;
      }
      dataToNotif.dateTime = this.getFormattedDateTimeForDisplay(notif.notif_datetime);
      return dataToNotif;
    });
  }

  async getSubscriptionLevel(id) {
    const { data } = await APIService.getSubscriptionByUser(id);
    const subscription = {
      level: "",
      expired: "",
      availableDashes: 0,
    };
    if (data.length === 0) {
      subscription.level = "Free";
    } else {
      subscription.level = this.capitalizeFirstLetter(data[0].level.name);
      subscription.availableDashes = data[0].level.nb_dashes;
      //var subscriptionDateTime = Date.parse(data.data[0].attributes.subscriptionDatetime);

      //TODO : il faudra gérer le cas de la sousciption expirée
      // var subscriptionExpiration = this.addMonthsToDate(subscriptionDateTime,data.data[0].attributes.lengthSubscriptionInMonth)

      // if (subscriptionExpiration < Date.now()){
      //   subscription.expired="true";
      // }else{
      //   subscription.expired="false";
      // }
    }
    return subscription;
  }

  async getNumberOngoingProjectsByUser(id) {
    const { data } = await APIService.getProjectsByUser(id);
    return data.length;
  }

  async getNumberGenolistsByUser(id) {
    const { data } = await APIService.getMyGenolists();
    return data.length;
  }

  async getOccupiedDashesByUser(id) {
    const { data } = await APIService.getDashesByUser(id);
    return data.length;
  }

  /**
   *
   * @param {*} string
   * @returns string with the first letter on UpperCase
   */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   *
   * @param {String} originalDateTime as received from database, for example 2023-02-21T06:29:00:000Z
   * @returns a String for the formatted DateTime, for example 21 FEB 2023 at 06:29
   */
  getFormattedDateTimeForDisplay(originalDateTime) {
    var day = originalDateTime.slice(8, 10);
    var month = "";
    switch (originalDateTime.slice(5, 7)) {
      case "01":
        month = "JAN";
        break;
      case "02":
        month = "FEB";
        break;
      case "03":
        month = "MAR";
        break;
      case "04":
        month = "APR";
        break;
      case "05":
        month = "MAY";
        break;
      case "06":
        month = "JUN";
        break;
      case "07":
        month = "JUL";
        break;
      case "08":
        month = "AUG";
        break;
      case "09":
        month = "SEP";
        break;
      case "10":
        month = "OCT";
        break;
      case "11":
        month = "NOV";
        break;
      case "12":
        month = "DEC";
        break;
    }
    var year = originalDateTime.slice(0, 4);
    var time = originalDateTime.slice(11, 16);
    return day + " " + month + " " + year + " at " + time;
  }

  getMetadataCondition(id) {
    let metadataX = [];
    let metadataY = [];
    let dataToReturn = [];

    try {
      APIService.getComparison(id).then((data) => {
        //je récupère ici les conditions qui ont été comparées pour l'élaboration de la genolist
        //dataToReturn["conditions"] = data.data.conditions;
        let countPerso = 0;
        data.data.conditions.forEach((condition) => {
          //ici, pour chacune des conditions, je vais récupérer les samples sur lesquels elles ont été respectivement appliquées
          APIService.getCondition(condition.id).then((data2) => {
            if (countPerso === 0) {
              //setSamplesConditionX(data2.data.samples);
              let metadata = {};
              let keys = [];

              data2.data.samples.forEach((sample) => {
                Object.entries(sample.metadata).forEach(([key, value]) => {
                  if (metadata.hasOwnProperty(key)) {
                    metadata[key].push(value);
                  } else {
                    metadata[key] = [value];
                    //je stocke ici les différentes clefs pour pouvoir naviguer et itérer ensuite dans les metadata
                    keys.push(key);
                  }
                });
              });

              keys.forEach((key) => {
                // je transforme le tableau avec toutes les occurrences par un tableau d'objets
                // qui stockent chaque occurrence unique et le nombre de fois où elle apparaît
                let result = Object.values(
                  metadata[key].reduce((r, s) => {
                    !r[s] ? (r[s] = { value: s, count: 1 }) : (r[s]["count"] += 1);
                    return r;
                  }, {})
                );
                metadataX[key] = result;
              });

              metadataX["conditionName"] = condition.name;
              countPerso += 1;
            } else {
              //setSamplesConditionY(data2.data.samples);
              let metadata = {};
              let keys = [];

              data2.data.samples.forEach((sample) => {
                Object.entries(sample.metadata).forEach(([key, value]) => {
                  if (metadata.hasOwnProperty(key)) {
                    metadata[key].push(value);
                  } else {
                    metadata[key] = [value];
                    //je stocke ici les différentes clefs pour pouvoir naviguer et itérer ensuite dans les metadata
                    keys.push(key);
                  }
                });
              });

              keys.forEach((key) => {
                // je transforme le tableau avec toutes les occurrences par un tableau d'objets
                // qui stockent chaque occurrence unique et le nombre de fois où elle apparaît
                let result = Object.values(
                  metadata[key].reduce((r, s) => {
                    !r[s] ? (r[s] = { value: s, count: 1 }) : (r[s]["count"] += 1);
                    return r;
                  }, {})
                );
                metadataY[key] = result;
              });

              metadataY["conditionName"] = condition.name;
            }
          });
        });
      });
      // console.log(metadataX);
      // console.log(metadataY);
      dataToReturn["conditionX"] = metadataX;
      dataToReturn["conditionY"] = metadataY;
      // console.log(dataToReturn)
      // console.log(dataToReturn["conditions"]);
      return dataToReturn;
    } catch (error) {
      console.log("error", error);
    }
  }
}

export default new Utils();
