import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//Custom components
import InfoFactor from "../InfoFactor";

function Comparison(props) {
  const comparison = props.comparison;
  const [factors, setFactors] = useState("");

  useEffect(() => {
    (async () => {})();
  }, []);

  if (comparison.main_factor === null || comparison.secondary_factor === null) {
    return (
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Fetching data{" "}
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InfoFactor factor={comparison.main_factor} normalization={comparison.normalization} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoFactor
              factor={comparison.secondary_factor}
              normalization={comparison.normalization}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Comparison;
