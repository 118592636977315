import { useEffect, useState } from "react";
import APIService from "services/DjangoAPI";
import BoxplotBis from "sciliciumLayouts/components/Charts/BoxplotBis";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Plot from "react-plotly.js";

function BoxPlotWrapper(props) {
  const listOfGenes = props.listOfGenes;
  const defaultIndex = props.defaultIndex;
  const genolist = props.genolist;
  const [selectedGene, setSelectedGene] = useState(listOfGenes[defaultIndex]);
  const [dataPlot, setDataPlot] = useState([]);
  const [loading, setLoading] = useState(true);
  /* This code was inserted to get the min and max values of the boxplots to set the same scale for both. 
  As plotly apparently doesn't like hot reloads, we won't use it for now. I let it here in case we need it later.*/
  //const updateMinMaxValues = props.updateMinMaxValues;

  useEffect(() => {
    const fetchData = async () => {
      if (selectedGene) {
        try {
          const { data } = await APIService.getBoxplotFromGenolistAndGenoref(
            genolist.id,
            selectedGene.id
          );
          setDataPlot(data);

          // See comment above variable declaration.
          // // Find the min and max values of the boxplot
          // const mainMin = Math.min(...data.main_factor);
          // const mainMax = Math.max(...data.main_factor);
          // const secondaryMin = Math.min(...data.secondary_factor);
          // const secondaryMax = Math.max(...data.secondary_factor);

          // updateMinMaxValues({
          //   min: Math.min(mainMin, secondaryMin),
          //   max: Math.max(mainMax, secondaryMax),
          // });

          setLoading(false);
        } catch (error) {
          console.log("error", error);
        }
      }
    };

    fetchData();
  }, [selectedGene, genolist.id]);

  // Define a function to handle gene selection
  const handleGeneSelection = (event, newValue) => {
    setSelectedGene(newValue);
  };

  return (
    <MDBox mr={3}>
      {/* Dropdown for gene selection */}
      <MDBox ml={2}>
        <div>
          <Autocomplete
            value={selectedGene}
            onChange={handleGeneSelection}
            options={listOfGenes}
            getOptionLabel={(option) => option.symbols}
            renderInput={(params) => (
              <TextField {...params} label="Select a gene" variant="outlined" />
            )}
          />
        </div>
      </MDBox>
      <MDBox pt={2} pb={2}>
        {/* BoxPlot based on selected gene */}
        {selectedGene && !loading ? (
          <BoxplotBis
            data={dataPlot}
            comparison={genolist.comparison}
            ytitle="Normalized count"
            xtitle=""
            title={"Expression of " + selectedGene.symbols}
            legend={false}
          />
        ) : (
          <Skeleton variant="rectangular">
            <Plot />
          </Skeleton>
        )}
      </MDBox>
    </MDBox>
  );
}

export default BoxPlotWrapper;
