// Hooks
import { useContext, useEffect, useState } from "react";
import { UploadContext } from "..";

// UI Components
import {
  Card,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UploadButton from "sciliciumLayouts/components/Buttons/UploadButton";
import DownloadButton from "sciliciumLayouts/components/Buttons/DownloadButton";
import LinkButton from "sciliciumLayouts/components/Buttons/LinkButton";
import WarningModal from "sciliciumLayouts/components/WarningModal";

const ReviewScreen = () => {
  const context = useContext(UploadContext);

  const [onOverwrite, setOnOverwrite] = useState(false);

  const handleOverwriteClick = () => setOnOverwrite(true);
  const handleOverwriteClose = () => setOnOverwrite(false);

  function confirmAction() {
    if (context.projectData.samples.length > 0) {
      return (
        <MDButton
          color="primary"
          flexGrow={2}
          variant="gradient"
          disabled={!factorsOK || !samplesOK || !comparisonsOK}
          onClick={handleOverwriteClick}
        >
          Confirm
        </MDButton>
      );
    }
    return (
      <Tooltip title="Please check all boxes to confirm the information before proceeding">
        <span>
          <MDButton
            color="primary"
            flexGrow={2}
            variant="gradient"
            disabled={!factorsOK || !samplesOK || !comparisonsOK}
            onClick={context.handleConfirmSubmit}
          >
            Confirm
          </MDButton>
        </span>
      </Tooltip>
    );
  }

  // State handling confirmation checkboxes
  const [{ factorsOK, samplesOK, comparisonsOK }, setConfirmation] = useState({
    factorsOK: false,
    samplesOK: false,
    comparisonsOK: false,
  });

  const handleCheckFactors = (event) => {
    setConfirmation((prev) => ({ ...prev, factorsOK: event.target.checked }));
  };

  const handleCheckSamples = (event) => {
    setConfirmation((prev) => ({ ...prev, samplesOK: event.target.checked }));
  };

  const handleCheckComparisons = (event) => {
    setConfirmation((prev) => ({ ...prev, comparisonsOK: event.target.checked }));
  };

  // State handling table data
  const [
    { factorRows, factorColumns, sampleRows, sampleColumns, comparisonRows, comparisonColumns },
    setTableData,
  ] = useState({
    factorRows: [],
    factorColumns: [],
    sampleRows: [],
    sampleColumns: [],
    comparisonRows: [],
    comparisonColumns: [],
  });

  const factorsTableData = {
    columns: factorColumns,
    rows: factorRows,
  };

  const samplesTableData = {
    columns: sampleColumns,
    rows: sampleRows,
  };

  const comparisonsTableData = {
    columns: comparisonColumns,
    rows: comparisonRows,
  };

  /**
   * Calculates factor table rows. Transposes the factors to display levels vertically.
   */
  function transpose(factors) {
    let rows = [];
    let maxLength = Math.max(...factors.map((factor) => factor.levels.length));
    for (let i = 0; i < maxLength; i++) {
      let row = {};
      for (let j = 0; j < factors.length; j++) {
        let factor = factors[j];
        let level = factor.levels[i];
        row[factor.name] = level ? level : "";
      }
      rows.push(row);
    }
    return rows;
  }

  /**
   * Calculates sample level cells.
   **/
  function getSampleLevels(sample) {
    console.log("getSampleLevels");
    let levels = {};
    sample.factors.forEach((f) => {
      let key = Object.keys(f)[0];
      levels[key] = f[key];
    });
    return levels;
  }

  useEffect(() => {
    // Gets data from context
    const data = context.toReview;

    // Calculate factor table data
    let calcFactorColumns = data.factors.map((factor) => ({
      Header: `${factor.name} (${factor.levels.length} conditions)`,
      accessor: factor.name,
      align: "center",
    }));
    let calcFactorRows = transpose(data.factors);

    // Calculate sample table data
    let calcSampleColumns = [
      { Header: "name", accessor: "name", align: "center" },
      { Header: "species", accessor: "species", align: "center" },
    ];
    data.factors.forEach((factor) =>
      calcSampleColumns.push({ Header: factor.name, accessor: factor.name, align: "center" })
    );
    let calcSampleRows = data.samples.map((s) => ({
      ...getSampleLevels(s),
      name: s.name,
      species: s.species,
    }));

    // Calculate comparison table data
    let calcComparisonColumns = [
      { Header: "condition factor", accessor: "factor", align: "center" },
      { Header: "first condition", accessor: "first", align: "center" },
      { Header: "second condition", accessor: "second", align: "center" },
    ];
    let calcComparisonRows = data.comparisons.map((c) => ({
      factor: c.factorName,
      first: c.mainFactor,
      second: c.secondaryFactor,
    }));

    // Update state with calculated data
    setTableData((prev) => ({
      ...prev,
      factorRows: calcFactorRows,
      factorColumns: calcFactorColumns,
      sampleRows: calcSampleRows,
      sampleColumns: calcSampleColumns,
      comparisonRows: calcComparisonRows,
      comparisonColumns: calcComparisonColumns,
    }));
  }, [context.toReview]);

  return (
    <>
      <FormGroup aria-label="position" row>
        <WarningModal
          open={onOverwrite}
          onClose={handleOverwriteClose}
          title={"This project already has data uploaded"}
          message={
            <Typography id="modal-modal-description" variant="body1" sx={{ my: 3 }}>
              <strong>Samples</strong>, <strong>Experimental factors</strong> and{" "}
              <strong>Comparisons</strong> will be overwritten. Continue?
            </Typography>
          }
          action={
            <MDButton
              color="primary"
              flexGrow={2}
              variant="gradient"
              disabled={!factorsOK || !samplesOK || !comparisonsOK}
              onClick={context.handleConfirmSubmit}
            >
              Confirm
            </MDButton>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDTypography variant="h2" fontWeight="medium">
              One last step
            </MDTypography>
            <MDTypography variant="h4" fontWeight="light">
              Please verify the information below and check the boxes to confirm that the data is
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <Card py={3} px={3}>
              <MDBox py={3} px={3}>
                <MDBox pb={3}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Experimental factors
                  </MDTypography>
                </MDBox>
                <DataTable
                  table={factorsTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
                <FormControlLabel
                  control={<Checkbox checked={factorsOK} onChange={handleCheckFactors} />}
                  label={
                    <Typography display="inline" variant="h6">
                      This information is correct
                    </Typography>
                  }
                  labelPlacement="end"
                  required
                  style={{ display: "flex" }}
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card py={3} px={3}>
              <MDBox py={3} px={3}>
                <MDTypography variant="h4" fontWeight="medium">
                  Samples description
                </MDTypography>
                <DataTable
                  table={samplesTableData}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  isSorted={false}
                  noEndBorder
                />
                <FormControlLabel
                  control={<Checkbox checked={samplesOK} onChange={handleCheckSamples} />}
                  label={
                    <Typography display="inline" variant="h6">
                      This information is correct
                    </Typography>
                  }
                  labelPlacement="end"
                  required
                  style={{ display: "flex" }}
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card py={3} px={3}>
              <MDBox py={3} px={3}>
                <MDTypography variant="h4" fontWeight="medium">
                  Comparisons
                </MDTypography>

                <DataTable
                  table={comparisonsTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
                <FormControlLabel
                  control={<Checkbox checked={comparisonsOK} onChange={handleCheckComparisons} />}
                  label={
                    <Typography display="inline" variant="h6">
                      This information is correct
                    </Typography>
                  }
                  required
                  labelPlacement="end"
                  style={{ display: "flex" }}
                />
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card py={3} px={3}>
              <MDBox display="flex" p={3} gap={3}>
                {confirmAction()}
                <DownloadButton
                  variant="outlined"
                  text="Download a new template"
                  download={context.handleDownloadClick}
                />
                <UploadButton
                  variant="outlined"
                  text="Upload another file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  maxSize={100}
                  onLoad={context.handleUploadClick}
                />
                <LinkButton
                  variant="outlined"
                  color="error"
                  text="Cancel"
                  to={`/projects/${context.projectData.id}`}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};

export default ReviewScreen;
