/**
=========================================================
* SciLicium genoApp - v0.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Author: Thomas Darde
Coded by www.creative-tim.com
Modified by SciLicium
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ChemicalsTable from 'sciliciumLayouts/components/Tables/ChemicalsTable';
import ExpCondTable from 'sciliciumLayouts/components/Tables/ExpCondTable'


import withAuth from 'services/withAuth';
// Loader
import PacmanLoader from "react-spinners/PacmanLoader";

// API service
import APIService from "services/APIService"

class ToxicogenomicsDashboard extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             chemicals: [],
             exp_cond: [],
             chemicals_number: 0,
             exp_cond_number: 0,
             loading: true
        }
    }
    
    async componentDidMount(){
        await APIService.getChemicals().then((data) => {
            this.setState({ chemicals: data.data })
          })
          .catch(function (ex) {
              console.log('Response parsing failed. Error: ', ex);
          });;
        await APIService.getChemicalsNumber().then((data) => {
            this.setState({ chemicals_number: data.data })
          })
          .catch(function (ex) {
              console.log('Response parsing failed. Error: ', ex);
          });;
        await APIService.getExperimentals().then((data) => {
            this.setState({ exp_cond: data.data })
            console.log(this.state.exp_cond)
          })
          .catch(function (ex) {
              console.log('Response parsing failed. Error: ', ex);
          });;
        await APIService.getExperimentalsNumber().then((data) => {
            this.setState({ exp_cond_number: data.data })
          })
          .catch(function (ex) {
              console.log('Response parsing failed. Error: ', ex);
          });;
        this.setState({loading:false})
    }

    render() {
  
        return (
            <DashboardLayout>
            <DashboardNavbar />
            {this.state.loading?
                <PacmanLoader
                        loading={this.state.loading}
                        size={50}
                        color="#36d7b7"
                        aria-label="Loading Spinner"
                        data-testid="loader"
                />:
                <MDBox py={3}>
                    <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <DefaultInfoCard
                                    icon="science"
                                    title="Number of Chemicals"
                                    value={this.state.chemicals_number}
                                    color="primary"
                                />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <DefaultInfoCard
                                icon="biotech"
                                title="Experimental conditions"
                                value={this.state.exp_cond_number}
                                color="info"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <Card>
                                        <MDButton variant="gradient" color="info">
                                            <Icon>add</Icon>&nbsp; Add New
                                        </MDButton>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Card>
                                        <MDButton variant="gradient" color="info">
                                            <Icon>add</Icon>&nbsp; Add New
                                        </MDButton>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </MDBox>
                    <MDBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Card>
                                <MDBox pt={3} px={3}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Chemicals
                                    </MDTypography>
                                </MDBox>
                                <MDBox py={1}>
                                    <ChemicalsTable data={this.state.chemicals}/>
                                </MDBox>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Card>
                                <MDBox pt={3} px={3}>
                                    <MDTypography variant="h6" fontWeight="medium">
                                        Experimental conditions
                                    </MDTypography>
                                </MDBox>
                                <MDBox py={1}>
                                    <ExpCondTable data={this.state.exp_cond}/>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                    </MDBox>
                </MDBox>
            }
            <Footer />
            </DashboardLayout>
        );
    }
}

export default withAuth(ToxicogenomicsDashboard);
