import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, FormControl, FormControlLabel, Stack, Switch, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import APIService from "services/DjangoAPI"; // Assuming you have a similar service for API calls
import { getAuthenticatedUserId } from "tokenHelper"; // To get the current user's ID
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const NewProject = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: "",
    description: "",
    isPublic: true, // Project is public by default
  });
  const { name, description, isPublic } = form;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({ name: "", description: "" });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!name.trim()) errors.name = "Project name is required";
    // Add more validations as needed
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsSubmitting(true);

    const projectData = {
      name,
      description,
      public: isPublic,
      owner: getAuthenticatedUserId(), // Assuming this function returns the current user's ID
      creation_date: new Date().toISOString(),
      status: "APPROVED", //This will be changed in the future, once we implement a real process to handle quotes and validation of a project by the SciLicium team
    };

    try {
      const response = await APIService.createProject(projectData); // Replace with your actual API call
      navigate(`/projects/${response.data.id}`); // Redirect to the newly created project's detail page
    } catch (error) {
      console.error("Error creating project:", error);
      // Handle API errors (e.g., display an error message)
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox py={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MDTypography variant="h5">Create New Project</MDTypography>
            <MDBox>
              <FormControl fullWidth>
                <Stack mt={2} spacing={2}>
                  <TextField
                    name="name"
                    label="Project Name"
                    value={name}
                    onChange={handleChange}
                    error={!!formErrors.name}
                    helperText={formErrors.name || ""}
                  />
                  <TextField
                    name="description"
                    label="Description"
                    value={description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    error={!!formErrors.description}
                    helperText={formErrors.description || ""}
                  />
                  <FormControlLabel
                    control={<Switch name="isPublic" checked={isPublic} onChange={handleChange} />}
                    label="Public Project"
                  />
                </Stack>
                <Box mt={2}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Create Project
                  </MDButton>
                </Box>
              </FormControl>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default NewProject;
