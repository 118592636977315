/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'

// Material Dashboard 2 PRO React examples

import DataTable from "examples/Tables/DataTable";
import Cell from '../Cell'; 
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";


// Material Dashboard 2 PRO React examples

class ExpCondTable extends React.Component{


  formatExpConds=()=>{
    const tableRows = [];
    this.props.data.map((row, key)=> {
      const img_url = "https://strapi.scilicium.com"+row.chemical.structure[0].url
      const format_row = {
        name: row.name ,
        chemical: <Cell image={img_url} name={row.chemical.name} orders="" />,
        dose: row.concentration + " nM",
        time: row.time + " h",
        view: <Link href={"/expcondition/"+row.id}><MDButton color="primary" variant="gradient">View</MDButton></Link>
        
      }
      tableRows.push(format_row)

    })
    return tableRows
  }

  render() {
    const dataTableData = {
      columns : [
        { Header: "name", accessor: "name", width: "55%" },
        { Header: "chemical", accessor: "chemical" },
        { Header: "dose", accessor: "dose" },
        { Header: "time", accessor: "time" },
        { Header: "view", accessor: "view" },
      ],

      rows:[]
    };
    dataTableData.rows = this.formatExpConds();
    return (
      <DataTable table={dataTableData} entriesPerPage={true} />
    );
  }
}

export default ExpCondTable;
