import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";

function BarChart(props) {
  const [loading, setLoading] = useState(true);
  const deg = props.deg;
  const [dataBar, setDataBar] = useState([]);
  const comparison = props.comparison;
  const [layout, setLayout] = useState([]);

  useEffect(() => {
    setDataBar([
      {
        type: "bar",
        x: [deg.pos],
        y: [""],
        name: comparison.main_factor.level,
        orientation: "h",
        marker: {
          color: colors.success.main,
        },
      },
      {
        type: "bar",
        x: [deg.neg],
        y: [""],
        name: comparison.secondary_factor.level,
        orientation: "h",
        marker: {
          color: colors.info.main,
        },
      },
    ]);

    setLayout({
      barmode: "stack",
      title: "Gene Over-Expression",
      xaxis: { title: "Number of Genes" },
      yaxis: { title: "" },
      showlegend: true,
      height: 300,
    });

    setLoading(false);
  }, [deg, comparison]);

  return (
    <MDBox mt={3}>
      <Card className="chart-container">
        <MDBox pb={2}>
          <MDBox pl={3} pt={3}>
            <MDTypography variant="h5" fontWeight="medium">
              Overview
            </MDTypography>
          </MDBox>
        </MDBox>
        {!loading && <Plot data={dataBar} layout={layout} />}
        <MDBox mb={5} />
      </Card>
    </MDBox>
  );
}

export default BarChart;
