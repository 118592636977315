import { Alert, Form, Input, Spin } from "antd";

import Card from "@mui/material/Card";

import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import axiosInstance from "axiosApi";
import { setTokens } from "tokenHelper";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/scilicium/genolens.png";

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      // Cleanup function to cancel async tasks
      setIsLoading(false);
    };
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      // const value = {
      //     identifier: values.email,
      //     password: values.password,
      // };
      // const response = await fetch(`${API}/auth/local`, {
      //     method: "POST",
      //     headers: {
      //     "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(value),
      // });

      // const data = await response.json();
      // if (data?.error) {
      //     throw data?.error;
      // } else {
      //     // set the token
      //     setToken(data.jwt);

      //     // set the user
      //     setUser(data.user);

      //     message.success(`Welcome back ${data.user.username}!`);

      //     navigate("/profile", { replace: true });
      //}
      const response = await axiosInstance.post("/token/", {
        email: values.email,
        password: values.password,
      });
      axiosInstance.defaults.headers["Authorization"] = "JWT " + response.data.access;
      console.log(response);
      setTokens(response.data);
      navigate("/dashboard", { replace: true });
      return response;
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Something went wrong!");
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  const handleAlertClose = () => {
    setError("");
  };

  return (
    <BasicLayout>
      <Fragment>
      <MDBox
        component="img"
        src={bgImage}
        alt="Product Image"
        shadow="lg"
        borderRadius="lg"
        width="100%"
        mb={3}
      />
        <Card title="SignIn">
          {error ? (
            <Alert
              className="alert_error"
              message={error}
              type="error"
              closable
              afterClose={() => setError("")}
            />
          ) : null}
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <Form name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
            <MDBox pt={4} pb={3} px={3}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <Form.Item>
                <MDButton type="primary" htmlType="submit" className="login_submit_btn">
                  Login {isLoading && <Spin size="small" />}
                </MDButton>
              </Form.Item>
            </MDBox>
          </Form>
        </Card>
      </Fragment>
    </BasicLayout>
  );
};

export default SignIn;
