/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'

// Material Dashboard 2 PRO React examples

import DataTable from "examples/Tables/DataTable";
import Cell from '../Cell'; 
import MDButton from "components/MDButton";
import Link from "@mui/material/Link";


// Material Dashboard 2 PRO React examples

class CondTable extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
             chemicals: {
                chemical:{name:""},
                concentration:"",
                time:""
             },
        }
    }
    
    componentDidMount(){
        this.setState({ chemicals: this.props.data })
    }


  render() {
    const dataTableData = {
      columns : [
        { Header: "name", accessor: "name", width: "55%" },
        { Header: "value", accessor: "value" },
      ],

      rows:[
        {name: "Chemical", value:this.state.chemicals.chemical.name },
        {name: "Dose", value:this.state.chemicals.concentration + " nM" },
        {name: "Time", value:this.state.chemicals.time + " h" },
      ]
    };
    return (
      <DataTable table={dataTableData} entriesPerPage={false} showTotalEntries={false}/>
    );
  }
}

export default CondTable;
