const fakeDataDash = [
    {
      name: "HepaRG",
      views: 235,
      owner: "Margaret Hamilton",
      link: "https://scilicium.com"
    },
    {
      name: "HepaRG exposure to EDs",
      views: 245,
      owner: "Grace Hopper",
      link: "https://scilicium.com"
    },
    {
      name: "HeBisphenol AaRG",
      views: 203,
      owner: "Rosalind Franklin",
      link: "https://scilicium.com"
    },
    {
      name: "Testis",
      views: 197,
      owner: "Barbara McClintock",
      link: "https://scilicium.com"
    },
    {
      name: "Kidney exposure to reprotoxicant",
      views: 185,
      owner: "Rachel Carson",
      link: "https://scilicium.com"
    }
  ]

  export default fakeDataDash;