/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

function DashChart({ dataDash }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">My Dash</MDTypography>
      </MDBox>
      {dataDash.datasets.data.length > 0 ? (
        <MDBox mt={3}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <PieChart chart={dataDash} height="18rem" />
            </Grid>
            <Grid item xs={4}>
              <MDBox pr={1}>
                <MDBox mb={1}>
                  <MDBadgeDot color="info" size="lg" badgeContent="Occupied" />
                </MDBox>
                <MDBox mb={1}>
                  <MDBadgeDot color="success" size="lg" badgeContent="Available" />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox p={2}>
          <MDTypography variant="h6" fontWeight="light">
            Your subscription doesn't allow for you to create a dash.
          </MDTypography>
        </MDBox>
      )}
      <MDBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="h5" color="text" fontWeight="light">
            No more dash available ?
          </MDTypography>
        </MDBox>
        <MDBox width={{ xs: "100%", sm: "60%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
          <MDButton size="large" color={darkMode ? "secondary" : "primary"}>
            Get an upgrade
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DashChart;
