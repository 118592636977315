import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Card } from "antd";

function InfoSample(props) {
  const [isLoading, setIsLoading] = useState(true);
  const factors = props.factors;
  const [listGenocounts, setListGenocounts] = useState("");
  const species = props.species;
  const sampleId = props.sampleId;

  const fetchData = () => {};

  useEffect(async () => {
    await fetchData();
    setIsLoading(false);
  }, []);

  return (
    <Card>
      {isLoading ? (
        <div>Retrieving data... </div>
      ) : (
        <>
          <MDBox pb={2}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium">
                Metadata
              </MDTypography>
            </MDBox>
            {species !== null ? (
              <MDBox justifyContent="space-between" pl={3} pr={3} pt={1} pb={0}>
                <MDTypography variant="h6" fontWeight="regular">
                  Species: {species.genus} {species.species} ({species.vernacular})
                </MDTypography>
              </MDBox>
            ) : (
              <div></div>
            )}
            {/* iterate through the factors array to display every factorlevel ans its FactorName */}
            {factors.length > 0 && factors[0] !== null ? (
              factors.map((factor) => (
                <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={0}>
                  <MDTypography variant="h6" fontWeight="regular">
                    {factor.factor_name.name}: {factor.level}
                  </MDTypography>
                </MDBox>
              ))
            ) : (
              <div></div>
            )}
          </MDBox>
        </>
      )}
    </Card>
  );
}

export default InfoSample;
