import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";

function VolcanoPlot(props) {
  const comparison = props.comparison;
  const genolistName = props.genolistName;
  const data = props.data;
  const [plotData, setPlotData] = useState([]);
  const [layout, setLayout] = useState([]);
  const [legendTraces, setLegendTraces] = useState([]);
  const [settingsPlot, setSettingsPlot] = useState({
    pValueThreshold: 0.05,
    log2FCfilter: 0,
  });

  useEffect(() => {
    if (data) {
      //Preparing the data for the volcano plot
      setPlotData([
        {
          x: data.map((d) => d.log_2_FC),
          y: data.map((d) => d.minus_log10_pvalue),
          mode: "markers",
          type: "scatter",
          text: data.map((d) => d.symbols),
          marker: {
            color: data.map((d) => {
              if (d["minus_log10_pvalue"] < -Math.log10(settingsPlot.pValueThreshold)) {
                return "gray";
              } else {
                return d.log_2_FC > 0 ? colors.success.main : colors.info.main;
              }
            }),
            colorscale: "Viridis",
            size: 5,
            showscale: false,
          },
          name: "Genes", // The main data series label
        },
      ]);

      // Custom legend data
      const customLegendData = [
        { color: "gray", label: "Non-Significant" },
        {
          color: colors.info.main,
          label: "Genes over-expressed in " + comparison.secondary_factor.level,
        },
        {
          color: colors.success.main,
          label: "Genes over-expressed in " + comparison.main_factor.level,
        },
      ];

      // Adding the custom legend data to the plot data. Not final yet, the result isn't satisfying
      setLegendTraces(
        customLegendData.map((entry) => ({
          name: entry.label,
          marker: { color: entry.color, size: 5 },
          x: [null], // No data points
          y: [null], // No data points
          showlegend: true,
          hoverinfo: "none",
        }))
      );

      setLayout({
        title: "Volcano Plot for genolist " + genolistName,
        xaxis: {
          title: "log2FC",
        },
        yaxis: {
          title: "-Log10 Adjusted p-value",
        },
        hovermode: "closest",
        showlegend: false,
        autosize: true,
        margin: {
          l: 80, // left margin
          r: 30, // right margin (reduce this value)
          b: 50, // bottom margin
          t: 30, // top margin
          pad: 4,
        },
      });
    }
  }, [data]);

  // Handling the input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettingsPlot((prevValues) => ({ ...prevValues, [name]: Number(value) }));
  };

  // Custom legend data
  const customLegendData = [
    { color: "gray", label: "Non-Significant" },
    {
      color: colors.info.main,
      label: "over-expressed in " + comparison.secondary_factor.level,
    },
    {
      color: colors.success.main,
      label: "over-expressed in " + comparison.main_factor.level,
    },
  ];

  return (
    <div>
      <Plot data={plotData} layout={layout} style={{ width: "100%", height: "100%" }} />
      <MDBox display="flex" justifyContent="space-evenly" mt={2} mb={2}>
        {customLegendData.map((entry, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              marginBottom: "2px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                margin: 3,
              }}
            ></div>
            <MDTypography variant="subtitle2">{entry.label}</MDTypography>
          </div>
        ))}
      </MDBox>

      {/* This code is kept commented for futur use as we would like to add a settings panel for the volcano plot in the next version

         <Grid item xs={12} sm={6}>
            <MDBox>
              <MDTypography variant="h5">Settings for the Volcano plot</MDTypography>
              <MDBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <FormField
                      type="number"
                      label="Significance threshold"
                      placeholder={settingsPlot.pValueThreshold}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormField
                      type="number"
                      label="Log2FC filter"
                      placeholder={settingsPlot.log2FCfilter}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Grid> */}
    </div>
  );
}

export default VolcanoPlot;
