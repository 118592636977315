import DataTable from "examples/Tables/DataTable";
import ListCell from "sciliciumLayouts/components/Tables/ListCell";
import DefaultCell from "sciliciumLayouts/components/Tables/DefaultCell";
import APIService from "services/DjangoAPI";
import React from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import Utils from "services/Utils";
import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";

class ListProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const tableRows = [];
    const fetchNbGenolists = async (id) => {
      const list = await APIService.getGenolistsByProject(id);
      return list.data.length;
    };

    for (const element of this.props.data) {
      const nbGenolists = await fetchNbGenolists(element.id);
      const friendlyDateTime = Utils.getFormattedDateTimeForDisplay(element.creation_date);
      const format_row = {
        name_description: (
          <ListCell
            name={element.name}
            description={element.description}
            id={element.id}
            type="project"
          />
        ),
        nb_genolists: <DefaultCell>{String(nbGenolists)}</DefaultCell>,
        creation_date: <DefaultCell>{friendlyDateTime}</DefaultCell>,
        status: <DefaultCell>{element.status}</DefaultCell>,
      };
      tableRows.push(format_row);
    }

    this.setState({ rows: tableRows });
    this.setState({ loading: false });
  };

  render() {
    const dataList = {
      columns: [
        { Header: "name and description", accessor: "name_description", width: "55%" },
        { Header: "number of genolists", accessor: "nb_genolists", align: "center" },
        { Header: "creation date", accessor: "creation_date", align: "center" },
        { Header: "status", accessor: "status", align: "center" },
      ],
      rows: this.state.rows,
    };

    if (this.state.loading) {
      return <TableSkeleton key="project-list" rows={10} cols={4} title={false} twoLinedFirstCell={true} mt={1} />;
    }
    return (
      <DataTable
        table={dataList}
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
      />
    );
  }
}

export default ListProjects;
