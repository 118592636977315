const toolTipsTexts = {
  enrichmentDotPlot:
    "This dotplot features the main enriched terms of the aformentionned ORA analysis, with their ratio of genes in the query (the total amount of differentially expressed genes) on the x axis. The flat amount of the querys' genes belonging to the term and the adjusted p-values are also represented as dot size and color respectively. ",
  volcanoPlot:
    "A volcano plot is a type of scatter-plot that is used to quickly identify changes in large data sets composed of replicate data. It plots likelihood versus fold-change on the y and x axes, respectively. The red dots represent genes over-expressed and the green ones the genes under-expressed in the comparison. The gene names displayed are the 50 genes with the lowest p-value. ",
  MAPlot:
    "An MA-plot is a plot of log-fold change (M-values, i.e. the log of the ratio of level counts for each gene between two conditions) against the mean counts (A-values, i.e. the average level counts for each gene across the two conditions). The red dots represent genes over-expressed and the green ones the genes under-expressed in the comparison. The gene names displayed are the 50 genes with the lowest p-value.",
  enrichmentGO:
    "Differentially expressed genes are further studied in an over-representation analysis (ORA) using the Gene Ontology (GO) and the clusterProfiler R package. ORA consists in mapping genes to meaningfull terms (here related to gene functions), and calculating the likelihood of seeing that many differentially expressed genes for any specific term if the differentially expressed genes were evenly distributed among terms. This is performed using an hypergeomtric test, where p-values are corrected using the Benjamini-Hochberg procedure and using an arbitrary significance threshold of adjusted p-value below 0.05. ",
  sequenceDistribution:
    "The read distribution shows, for all samples, the number of reads assigned, aligned on the reference transcriptome, aligned on known refseq sequences and aligned on known refseq sequences taking into account the Unique Molecule Identifier (duplicate UMIs removed). ",
  genesDetected:
    "The number of genes detected by sample is an important quality control. Samples with too few detected genes may cause the comparisons to fail when trying to detect genes differentially expressed.",
  PCAPlot:
    "The Principal Component Analysis groups samples according to their transcriptomic profiles. ",
  samplesCorrelation:
    "The Correlation Heatmap clusters the samples according to their transcriptomic profiles. ",
  samplesQualityControl:
    "SciLicium's quality control is based on the number of aligned reads and detected genes. If the number of detected genes falls below the defined threshold, the sample is marked as fail. If the number of reads falls below the defined threshold, the sample is marked with a warning.",
};

export default toolTipsTexts;
