import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

const DataTableCustom = ({ columns, rows, pageSizeOptions, sorting, onSelectionChange }) => {
  return (
    <div style={{ height: 557, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSizeOptions[0]}
        rowsPerPageOptions={pageSizeOptions}
        checkboxSelection={false}
        disableSelectionOnClick
        sorting={sorting}
        onSelectionModelChange={onSelectionChange}
        pagination
        sx={{ color: colors.dark.main }}
      />
    </div>
  );
};

DataTableCustom.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  sorting: PropTypes.object,
  onSelectionChange: PropTypes.func,
};

DataTableCustom.defaultProps = {
  pageSizeOptions: [10, 25, 50, 100], // Set default page size options
};

export default DataTableCustom;
