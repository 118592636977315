import { AUTH_TOKEN_ACCESS, AUTH_TOKEN_REFRESH, USER_ID } from "./constants";
import APIService from "./services/DjangoAPI";
import { decodeToken } from "react-jwt";

export const getToken = (typeToken) => {
  return localStorage.getItem(typeToken);
};

export const setTokens = async (responseData) => {
  if (responseData) {
    localStorage.setItem(AUTH_TOKEN_ACCESS, responseData.access);
    localStorage.setItem(AUTH_TOKEN_REFRESH, responseData.refresh);
    try {
      const decoded = await decodeToken(responseData.access);
      localStorage.setItem(USER_ID, decoded.user_id);
    } catch (error) {
      console.error("Error decoding token", error);
    }
  }
};

export const removeToken = () => {
  //Blacklist refresh token
  APIService.postLogoutUser();
  localStorage.removeItem(AUTH_TOKEN_ACCESS);
};

export const removeAuthenticatedUserId = () => {
  localStorage.removeItem(USER_ID);
};

export const getAuthenticatedUserId = () => {
  return localStorage.getItem(USER_ID);
};
