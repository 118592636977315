import { Box, Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material";

const TableSkeleton = ({
  rows = 5,
  cols = 3,
  title = true,
  twoLinedFirstCell = false,
  ...muiProps
}) => {
  const renderCells = (r) => {
    const cells = [];
    for (let i = 0; i < cols; i++) {
      cells.push(
        <TableCell key={`cell-${r}-${i}`} width={i === 0 && "50%"}>
          {i === 0 && r !== 0 && twoLinedFirstCell ? (
            <>
              <Skeleton key={`cell-${r}-${i}-content1`} width="50%" />
              <Skeleton key={`cell-${r}-${i}-content2`} fullWidth />
            </>
          ) : (
            <Skeleton key={`cell-${r}-${i}-content`} />
          )}
        </TableCell>
      );
    }
    return cells;
  };

  const renderRows = () => {
    const _rows = [];
    for (let i = 0; i < rows; i++) {
      _rows.push(<TableRow key={`row-${i}`}>{renderCells(i)}</TableRow>);
    }
    return _rows;
  };

  return (
    <Box {...muiProps}>
      {title && <Skeleton width={300} />}
      <Table>
        <TableBody>{renderRows()}</TableBody>
      </Table>
    </Box>
  );
};
export default TableSkeleton;
