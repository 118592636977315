import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function KeySelector({ json, onChange }) {
  const keys = Object.keys(json);

  const handleChange = (_, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      options={keys}
      renderInput={(params) => <TextField {...params} label="Select Key" />}
      onChange={handleChange}
    />
  );
}