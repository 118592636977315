import { useEffect, useState } from "react";
import APIService from "services/DjangoAPI";
import Plot from "react-plotly.js";
import MDBox from "components/MDBox";
import { Card, Autocomplete, IconButton, Skeleton, TextField } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import MDTypography from "components/MDTypography";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";
import toolTipsTexts from "assets/toolTipsTexts";

function PCAPlot(props) {
  const [axisOptions, setAxisOptions] = useState([]);
  const [selectedXAxis, setSelectedXAxis] = useState("coord_dim_0");
  const [selectedYAxis, setSelectedYAxis] = useState("coord_dim_1");
  const [plotData, setPlotData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFactor, setSelectedFactor] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const { data } = await APIService.getPCAdataByProject(props.projectId);
        setAxisOptions(Object.keys(data.axis_titles));
        setPlotData(data);
        setLoading(false);
        // Initialize selectedFactor with the first available factor name
        const sampleKeys = Object.keys(data.sample_info);
        if (sampleKeys.length > 0) {
          const firstSampleFactorNames = Object.keys(plotData.sample_info[sampleKeys[0]]);
          if (firstSampleFactorNames.length > 0) {
            setSelectedFactor(firstSampleFactorNames[0]); // Set to the first factor name
          }
        }
      } catch (error) {
        console.error("Error fetching PCA data:", error);
      }
    };

    fetchInitialData();
  }, [props.projectId, plotData]);

  const generateColorMap = (factorLevels) => {
    const colors = [
      "#0DBA13",
      "#c6fbc7",
      "#086408",
      "#39A0ED",
      "#abd7f7",
      "#0c4d7c",
      "#dc394a",
      "#fac0ba",
      "#6c1524",
      "#dda15e",
      "#f4ddc3",
      "#5e3b14",
      "#a85edd",
      "#e0c3f4",
      "#551b7e",
    ]; // predefined color list
    let colorMap = {};
    factorLevels.forEach((level, index) => {
      colorMap[level] = colors[index % colors.length];
    });
    return colorMap;
  };

  const renderPlot = () => {
    const parsedData = JSON.parse(plotData.data);
    const factorLevels = new Set(
      parsedData.map((row) => plotData.sample_info[row.sample][selectedFactor])
    );
    const colorMap = generateColorMap(Array.from(factorLevels));

    const colors = parsedData.map((row) => {
      const factorLevel = plotData.sample_info[row.sample][selectedFactor];
      return colorMap[factorLevel];
    });

    // Create a trace for each factor level
    const traces = Array.from(factorLevels).map((level) => {
      return {
        x: parsedData
          .filter((row) => plotData.sample_info[row.sample][selectedFactor] === level)
          .map((row) => row[selectedXAxis]),
        y: parsedData
          .filter((row) => plotData.sample_info[row.sample][selectedFactor] === level)
          .map((row) => row[selectedYAxis]),
        type: "scatter",
        mode: "markers",
        marker: { color: colorMap[level] },
        name: level, // Name of the trace, shown in the legend
      };
    });

    const hoverText = parsedData.map((row) => `Sample: ${row.sample}`);

    return (
      <MDBox pb={1} pr={1} pl={1}>
        <div style={{ height: 618 }}>
          <Plot
            data={traces}
            layout={{
              xaxis: { title: plotData.axis_titles[selectedXAxis] },
              yaxis: { title: plotData.axis_titles[selectedYAxis] },
              legend: { title: { text: selectedFactor }, orientation: "v" },
              margin: { l: 50, r: 50, b: 50, t: 50, pad: 4 },
              autosize: true,
            }}
            style={{ width: "100%", height: "100%" }}
          />
        </div>{" "}
      </MDBox>
    );
  };

  return (
    <Card>
      <MDBox pb={2}>
        <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Principal Component Analysis
          </MDTypography>
          <CustomTooltip title={toolTipsTexts.PCAPlot}>
            <IconButton sx={{ py: 0, pr: 3 }}>
              <HelpIcon />
            </IconButton>
          </CustomTooltip>
        </MDBox>
      </MDBox>
      {loading ? (
        <Skeleton>
          <Plot />
        </Skeleton>
      ) : (
        <MDBox>
          <MDBox display="flex" justifyContent="space-around">
            {/* X-Axis Autocomplete */}
            <Autocomplete
              style={{ width: "100%", margin: 15 }}
              value={selectedXAxis}
              onChange={(event, newValue) => {
                setSelectedXAxis(newValue);
              }}
              options={axisOptions}
              renderInput={(params) => <TextField {...params} label="X-Axis" />}
            />

            {/* Y-Axis Autocomplete */}
            <Autocomplete
              style={{ width: "100%", margin: 15 }}
              value={selectedYAxis}
              onChange={(event, newValue) => {
                setSelectedYAxis(newValue);
              }}
              options={axisOptions}
              renderInput={(params) => <TextField {...params} label="Y-Axis" />}
            />

            {/* Factor Autocomplete */}
            <Autocomplete
              style={{ width: "100%", margin: 15 }}
              value={selectedFactor}
              onChange={(event, newValue) => {
                setSelectedFactor(newValue);
              }}
              options={plotData.factor_names}
              renderInput={(params) => <TextField {...params} label="Factor" />}
            />
          </MDBox>

          {/* PCA Scatter Plot */}
          <MDBox pl={2} pb={2}>
            {renderPlot()}
          </MDBox>
        </MDBox>
      )}
    </Card>
  );
}

export default PCAPlot;
