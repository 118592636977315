// Hooks
import { useContext, useEffect, useState } from "react";
import { UploadContext } from "..";

// UI Elements
import { Alert, Button } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const ErrorTile = ({ value, detail, setNbErrors }) => {
  const [done, setDone] = useState(false);
  const context = useContext(UploadContext);

  /**
   * Increases or decreases display number of errors when marked/unmarked.
   */
  const handleClick = () => {
    setDone((done) => !done);
    if (done) {
      setNbErrors((nb) => nb + 1);
    } else {
      setNbErrors((nb) => nb - 1);
    }
  };

  useEffect(() => {
    setDone(false);
  }, [context.errors]);

  return (
    <Alert
      sx={{ marginY: "0.5rem" }}
      severity={done ? "success" : "error"}
      action={
        <Button color="inherit" onClick={handleClick}>
          {done ? "Unmark" : "Mark as done"}
        </Button>
      }
    >
      <MDBox display="flex" gap={3}>
        <MDTypography
          variant="h6"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "10rem",
          }}
        >
          {value}
        </MDTypography>
        <MDTypography
          variant="body2"
          sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {detail}
        </MDTypography>
      </MDBox>
    </Alert>
  );
};

export default ErrorTile;
