import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//Services
import APIService from "services/DjangoAPI";
import { Card, Skeleton } from "@mui/material";
import ListSamples from "../Tables/ListSamples";
import TableSkeleton from "../Skeletons/TableSkeleton";

function InfoFactor(props) {
  const [isLoading, setIsLoading] = useState(true);
  const factor = props.factor;
  const normalization = props.normalization;
  const [listSamples, setListSamples] = useState("");
  const [species, setSpecies] = useState([]);

  const fetchData = () => {
    try {
      APIService.getSamplesByFactor(factor.id).then((data) => {
        // retrieve the different species associated with the samples
        const uniqueSpecies = new Set();
        data.data.forEach((sample) => {
          // Convert the species object to a unique identifier (e.g., a string)
          const speciesIdentifier = JSON.stringify(sample.species);
          uniqueSpecies.add(speciesIdentifier);
        });
        // Convert the unique identifiers back to species objects
        const species_data = Array.from(uniqueSpecies).map((speciesIdentifier) =>
          JSON.parse(speciesIdentifier)
        );
        setSpecies(species_data);
        setListSamples(data.data);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(async () => {
    await fetchData();
    setIsLoading(false);
  }, []);

  return (
    <Card>
      {isLoading ? (
        <MDBox mt={3} ml={3} pb={2}>
          <MDBox>
            <Skeleton />
          </MDBox>
        </MDBox>
      ) : (
        <>
          <MDBox mt={3} ml={3} pb={2}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium">
                Summary for factor {factor.factor_name.name}: {factor.level}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={0}>
              <MDTypography variant="h6" fontWeight="regular">
                Number of samples: {listSamples.length}
              </MDTypography>
            </MDBox>
            {/* iterate through the species array to displey every species */}
            {species.length > 0 && species[0] !== null ? (
              <MDBox display="flex" justifyContent="space-between" pl={3} pr={3} pt={1} pb={1}>
                <MDTypography variant="h6" fontWeight="regular">
                  Species:{" "}
                  {species?.map((species_entry) => {
                    return (
                      species_entry.genus +
                      " " +
                      species_entry.species +
                      " (" +
                      species_entry.vernacular +
                      ") "
                    );
                  })}
                </MDTypography>
              </MDBox>
            ) : (
              <div></div>
            )}
          </MDBox>

          <MDBox ml={3} mr={3}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium">
                List of samples
              </MDTypography>
            </MDBox>
            {listSamples.length > 0 ? (
              <ListSamples listSamples={listSamples} normalization={normalization} />
            ) : (
              <TableSkeleton />
            )}
          </MDBox>
        </>
      )}
    </Card>
  );
}

export default InfoFactor;
