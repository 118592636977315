// Hooks
import { Link } from "react-router-dom";

// UI Components
import MDButton from "components/MDButton";

import "../../customStylingComponents.css";
import MDBox from "components/MDBox";

/**
 * A button that makes use of the React router link
 */
const LinkButton = ({ variant, text, color, to, target, rel, margin, display }) => {
  return (
    <MDBox m={margin} display={display || "inline"}>
      <Link to={to} target={target} rel={rel}>
        <MDButton color={color || "primary"} variant={variant || "outlined"}>
          {text}
        </MDButton>
      </Link>
    </MDBox>
  );
};

export default LinkButton;
