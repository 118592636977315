// Hooks
import { useEffect, useState } from "react";

// UI components
import {
  Box,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Skeleton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const GeneralTile = ({ data, userIsOwner, onSubmit }) => {
  const [form, setForm] = useState({ id: null, name: null, description: null, isPublic: null });
  const { id, name, description, isPublic } = form;
  const [formErrors, setFormErrors] = useState({ name: "", description: "" });
  const [updating, setUpdating] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const form = {
      id: data?.id,
      name: data?.name,
      description: data?.description,
      isPublic: data?.public,
    };
    setForm(form);
    setCanSubmit(false);
  }, [data]);

  const onSetPublic = (event) => {
    setCanSubmit(true);
    setForm((prev) => ({ ...prev, isPublic: event.target.checked }));
  };

  const onSetDescription = (event) => {
    setCanSubmit(true);
    setForm((prev) => ({ ...prev, description: event.target.value }));
  };

  const onSetName = (event) => {
    setCanSubmit(true);
    setForm((prev) => ({ ...prev, name: event.target.value }));
  };

  const validateForm = () => {
    setValid(true);
    if (!name || name.length > 255) {
      setValid(false);
      setFormErrors((prev) => ({ ...formErrors, name: "This name is too long (255 maximum)" }));
    }
    // No constraint for now
  };

  const handleSubmit = async () => {
    validateForm();
    if (valid) {
      setUpdating(true);
      const response = await onSubmit({ id, name, description, public: isPublic });
      if (response) setCanSubmit(false);
      setUpdating(false);
    } else {
      setCanSubmit(false);
    }
    setValid(true);
  };

  return (
    <Card>
      <MDBox py={3} px={3}>
        <MDTypography variant="h5">General</MDTypography>
        {data && name ? (
          <MDBox>
            <Chip color="success" size="small" label={`Project ${data.status.toLowerCase()}`} />
            {userIsOwner ? (
              <FormControl fullWidth>
                <Stack mt={2} spacing={2}>
                  <TextField
                    disabled
                    fullWidth
                    marginTop={2}
                    label="Name"
                    defaultValue={data.name}
                    onChange={onSetName}
                    helperText={`${name.length} / 255` || "0 / 255"}
                  />
                  <TextField
                    disabled={!userIsOwner}
                    fullWidth
                    multiline
                    rows={5}
                    label="Description"
                    defaultValue={data.description}
                    onChange={onSetDescription}
                  />
                </Stack>
                <FormControlLabel
                  disabled={!userIsOwner}
                  control={<Switch defaultChecked={data.public} onChange={onSetPublic} />}
                  label="Public"
                />
              </FormControl>
            ) : (
              <>
                <MDTypography variant="h4">{data.name}</MDTypography>
                <MDTypography variant="body1">{data.description}</MDTypography>
              </>
            )}
            <MDTypography variant="body2">
              Created on {data.createdAt.toLocaleDateString()}
            </MDTypography>
            <MDTypography variant="body2">
              Owner : {data.owner.username !== "" ? data.owner.username : data.owner.email}
            </MDTypography>
            <Box mt={2}>
              {userIsOwner && (
                <MDButton
                  variant="gradient"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={updating || !canSubmit}
                >
                  Save changes
                </MDButton>
              )}
            </Box>
          </MDBox>
        ) : (
          <MDBox fullWidth>
            <Skeleton height={25} width={"20%"} />
            <Skeleton height={40} />
            <Skeleton height={150} />
            <Skeleton width={"40%"} />
            <Skeleton width={"30%"} />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

export default GeneralTile;
