import "../resultsOverviewStyling.css";
import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import APIService from "services/DjangoAPI";
import { Card, Grid, IconButton, Skeleton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import toolTipsTexts from "assets/toolTipsTexts";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";
import ListSamples from "sciliciumLayouts/components/Tables/ListSamples";

function SamplesQualityControl(props) {
  const [loading, setLoading] = useState(true);
  const [plotData, setPlotData] = useState([]);
  const [listWarningOrFailSamples, setListWarningOrFailSamples] = useState([]);
  const projectId = props.projectId;
  const [layout, setLayout] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your actual data fetching method
        const { data } = await APIService.getSamplesQualityControlByProject(projectId);
        console.log("data", data);
        // Calculate passed samples assuming the rest are either warning or failed
        const passedSamples =
          data.nb_samples - data.failed_samples.length - data.warning_samples.length;

        // Define plot data for the bar chart
        const qualityControlData = [
          {
            x: ["Passed", "Warning", "Failed"],
            y: [passedSamples, data.warning_samples.length, data.failed_samples.length],
            type: "bar",
            marker: {
              color: [colors.success.main, colors.warning.main, colors.error.main],
            },
          },
        ];

        setPlotData(qualityControlData);
        const listSamples = data.warning_samples.concat(data.failed_samples);
        listSamples.sort((a, b) => (a.name > b.name ? 1 : -1));
        setListWarningOrFailSamples(listSamples);
        setLoading(false);
      } catch (error) {
        console.error("error", error);
      }
    };

    setLayout({
      barmode: "group",
      title: "Samples Quality Control",
      yaxis: { title: "Number of samples" },
      margin: { l: 50, r: 50, b: 50, t: 50, pad: 4 },
      showlegend: false,
      height: 400,
    });

    fetchData();
  }, [projectId]);

  return (
    <Card>
      <MDBox px={2}>
        <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Samples Quality Control
          </MDTypography>
          <CustomTooltip width={400} title={toolTipsTexts.samplesQualityControl}>
            <IconButton sx={{ py: 0, pr: 3 }}>
              <HelpIcon />
            </IconButton>
          </CustomTooltip>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item md={12} xl={4}>
            <MDBox pb={2} pr={2} pl={2}></MDBox>
            <MDBox pb={1} pr={1} pl={1}>
              <div>
                {loading ? (
                  <Skeleton>
                    <Plot />
                  </Skeleton>
                ) : (
                  <Plot
                    data={plotData}
                    layout={layout}
                    style={{ width: "100%", height: "100%" }}
                    className="custom-plot"
                  />
                )}
              </div>
            </MDBox>
          </Grid>
          <Grid item md={12} xl={8}>
            <MDBox pl={3} pt={3}>
              <MDTypography variant="h6" fontWeight="medium">
                List of samples with warning/fail status
              </MDTypography>
            </MDBox>
            <ListSamples
              listSamples={listWarningOrFailSamples}
              showStatusSample={true}
              showFactors={true}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SamplesQualityControl;
