import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Skeleton,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import APIService from "services/DjangoAPI";
import DataTableCustom from "sciliciumLayouts/components/Tables/DataTableCustom";
import { Link } from "react-router-dom";
import { GO_DATABASE } from "constants";
import colors from "assets/theme/base/colors";
import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";
import MDButton from "components/MDButton";
import "../../customStylingComponents.css";
import LinkButton from "sciliciumLayouts/components/Buttons/LinkButton";
import { GENECARD_DATABASE } from "constants";
import toolTipsTexts from "assets/toolTipsTexts";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";

function EnrichmentTable({ loading, enrichmentData }) {
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState([]);

  useEffect(() => {
    if (enrichmentData && !loading && enrichmentData.length > 0) {
      const tableRows = [];

      for (const value of enrichmentData) {
        let ratioGenesInTerm = (value.count / value.nb_genes_in_term).toFixed(3);
        let ratioGenesInQuery = (value.count / value.nb_genes_in_query).toFixed(3);

        tableRows.push({
          id: value.id,
          ontology: value.goref.ontology,
          goterm: value.goref.term,
          description: value.goref.description,
          count: value.count,
          ratioGenesInTerm: ratioGenesInTerm,
          ratioGenesInQuery: ratioGenesInQuery,
          pValueAdj: value.pvalue_adj,
        });
      }
      setRows(tableRows);
    }
  }, [enrichmentData, loading]);

  // Fetch the genes when expanding the row
  const fetchGenorefsForGOvalue = async (goValueId) => {
    const { data } = await APIService.getGenorefsForGOvalue(goValueId);
    return data;
  };

  const handleRowExpansion = async (goValueId) => {
    setOpenDialog(true);
    const genorefs = await fetchGenorefsForGOvalue(goValueId);
    setDialogContent(genorefs || []);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
    setDialogContent([]); // Reset the dialog content
  };

  const columns = [
    {
      headerName: "Genes",
      field: "expand",
      renderCell: (params) => (
        <MDButton color="success" onClick={() => handleRowExpansion(params.id)}>
          {/* {expandedRows[params.id] ? "Collapse" : "Expand"} */}
          Genes
        </MDButton>
      ),
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: "Ontology",
      field: "ontology",
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: "GO term",
      field: "goterm",
      align: "center",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => {
        //Render the cell content as a link to the GO term page
        return (
          <Link
            to={GO_DATABASE + params.value}
            target="_blank"
            rel="noreferrer"
            style={{ color: colors.dark.main }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      headerName: "GO description",
      field: "description",
      align: "center",
      flex: 3,
      headerAlign: "center",
    },
    {
      headerName: "Count",
      field: "count",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
    },
    {
      headerName: "Ratio of genes in term",
      field: "ratioGenesInTerm",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
    },
    {
      headerName: "Ratio of genes in query",
      field: "ratioGenesInQuery",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
    },
    {
      headerName: "p-value adjusted",
      field: "pValueAdj",
      align: "center",
      flex: 1,
      headerAlign: "center",
      type: "number",
      renderCell: (params) => {
        // If the first significant number is within the first three decimals, then we display it with three decimals, including the trailing zeros
        // Otherwise, we display it with scientific notation
        const absPValue = Math.abs(params.value);

        if (0.001 <= absPValue && absPValue < 1000) {
          // Format as a fixed number with three decimal places
          return parseFloat(params.value).toFixed(3);
        } else {
          // Format in scientific notation
          return parseFloat(params.value).toExponential(3);
        }
      },
    },
  ];

  return (
    <Card>
      <MDBox pb={2}>
        <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            GO enrichment
          </MDTypography>
          <CustomTooltip title={toolTipsTexts.enrichmentGO} width={500}>
            <IconButton sx={{ py: 0, pr: 3 }}>
              <HelpIcon />
            </IconButton>
          </CustomTooltip>
        </MDBox>
      </MDBox>
      {loading ? (
        <TableSkeleton rows={10} cols={7} />
      ) : (
        <div>
          <DataTableCustom columns={columns} rows={rows} />
          {/* Render the list of genes attached to a GO value in a dialog */}
          <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
            <DialogTitle>List of Genes</DialogTitle>
            <DialogContent sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {dialogContent.length === 0 ? (
                <Skeleton variant="text" width="100%" height={60} />
              ) : (
                dialogContent
                  .sort((a, b) => a.symbols.localeCompare(b.symbols))
                  .map((gene, index) => (
                    <LinkButton
                      color="info"
                      variant="contained"
                      size="medium"
                      key={index}
                      to={GENECARD_DATABASE + gene.symbols}
                      text={gene.symbols}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ))
              )}
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseDialog}>Close</MDButton>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Card>
  );
}

export default EnrichmentTable;
