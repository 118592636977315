const fakeDataGenolists = [
  {
    name: "Liver exposure to nitrazepam",
    views: 238,
    owner: "Margaret Hamilton",
    link: "https://scilicium.com",
  },
  {
    name: "HepaRG exposure to Bisphenol A",
    views: 225,
    owner: "Grace Hopper",
    link: "https://scilicium.com",
  },
  {
    name: "Lungs exposure to glyphosate",
    views: 216,
    owner: "Rosalind Franklin",
    link: "https://scilicium.com",
  },
  {
    name: "Maïs et cytogénétique",
    views: 184,
    owner: "Barbara McClintock",
    link: "https://scilicium.com",
  },
  {
    name: "Silent Spring",
    views: 155,
    owner: "Rachel Carson",
    link: "https://scilicium.com",
  }
]

export default fakeDataGenolists;
