// Hooks
import { useContext, useState } from "react";
import { UploadContext } from "..";

// UI Components
import { Button, Box, Step, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import UploadButton from "sciliciumLayouts/components/Buttons/UploadButton";
import DownloadButton from "sciliciumLayouts/components/Buttons/DownloadButton";
import LinkButton from "sciliciumLayouts/components/Buttons/LinkButton";
import {
  SciliciumStepper,
  SciliciumStepLabel,
  SciliciumStepContent,
  SciliciumConnector,
} from "sciliciumLayouts/components/SciliciumStepper";

// Styling
import colors from "assets/theme/base/colors";
import { UploadStepIcon } from "./stepperIcons";

const UploadScreen = () => {
  const context = useContext(UploadContext);

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Download the template",
      description: "Download the template file to describe the project.",
    },
    {
      label: "Enter project details",
      description:
        "Fill in the project description, including experimental variables, sample description, and conditions to compare. Detailed instructions are provided in the file.",
    },
    {
      label: "Upload your file",
      description:
        "Upload your filled template. Your data will be analyzed, and you will be asked to either edit your file to correct errors or simply confirm your information.",
    },
  ];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MDTypography variant="h2" fontWeight="medium">
          Project description
        </MDTypography>
        <MDTypography variant="h4" fontWeight="light">
          Upload the description file for <strong>{context.projectData.name}</strong>
        </MDTypography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: 700, minHeight: "65vh" }}>
          <SciliciumStepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<SciliciumConnector />}
          >
            {steps.map((step, index) => (
              <Step key={step.label}>
                <SciliciumStepLabel StepIconComponent={UploadStepIcon}>
                  {step.label}
                </SciliciumStepLabel>
                <SciliciumStepContent TransitionProps={{ unmountOnExit: false }}>
                  <MDTypography variant="body1" fontSize={18}>
                    {step.description}
                  </MDTypography>
                  {index === 0 && (
                    <Box display="flex" flexDirection="column" flex sx={{ margin: "8px" }}>
                      <DownloadButton download={context.handleDownloadClick} />
                    </Box>
                  )}
                  {index === 2 && (
                    <Box display="flex" flexDirection="column" sx={{ margin: "8px" }}>
                      <UploadButton
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxSize={100}
                        onLoad={context.handleUploadClick}
                      />
                    </Box>
                  )}
                  <Box sx={{ my: 2 }}>
                    <Button onClick={handleBack} sx={{ mr: 1 }} disabled={index === 0}>
                      Back
                    </Button>
                    {index < steps.length - 1 && (
                      <Button
                        onClick={handleNext}
                        variant="outlined"
                        sx={{ color: colors.primary.main, mr: 1 }}
                      >
                        Continue
                      </Button>
                    )}
                    {index === steps.length - 1 && (
                      <LinkButton
                        variant="outlined"
                        color="error"
                        text="Return to project"
                        to={`/projects/${context.projectData.id}`}
                      />
                    )}
                  </Box>
                </SciliciumStepContent>
              </Step>
            ))}
          </SciliciumStepper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UploadScreen;
