/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/*
This component is a variant of the dashboards>sales component from Tim Creative
*/
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "sciliciumLayouts/components/Footer";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Sales dashboard components
import DashChart from "sciliciumLayouts/components/DashChart";
import Timeline from "sciliciumLayouts/components/TimeLine";

// Data
import fakeDataGenolists from "./data/fakeDataGenolists";
import fakeDataDash from "./data/fakeDataDash";

import Utils from "services/Utils";
import APIService from "services/DjangoAPI";

import TopViews from "./data/TopViews.js";

import { useAuthContext } from "context/AuthContext";
import { getAuthenticatedUserId } from "tokenHelper";
import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";

const SciliciumDashboard = () => {
  const [error, setError] = useState(null);
  const { user, isLoading, setUser } = useAuthContext();
  const authenticatedUserId = getAuthenticatedUserId();
  const [subscription, setSubscription] = useState("");
  const [nbProjects, setNbProjects] = useState(0);
  const [nbGenolists, setNbGenolists] = useState(0);
  const [dataDash, setDataDash] = useState({
    labels: ["Occupied", "Available"],
    datasets: {
      label: "Dash",
      backgroundColors: ["primary", "success"],
      data: [],
    },
  });
  const [topDash, setTopDash] = useState({});
  const [topGenolists, setTopGenolists] = useState({});
  const [loading, setLoading] = useState(true);

  const getData = async (id) => {
    const subscription = await Utils.getSubscriptionLevel(id);
    const { data } = await APIService.getMyProjects()
    const nbProjects = data.length;
    const nbGenolists = await Utils.getNumberGenolistsByUser(id);
    //const occupiedDashes = await Utils.getOccupiedDashesByUser(id);
    const occupiedDashes = [2, 3];

    return {
      subscription,
      nbProjects,
      nbGenolists,
      occupiedDashes,
    };
  };

  const getTopViewsDash = () => {
    return { fakeDataDash };
  };

  const getTopViewsGenolists = () => {
    return { fakeDataGenolists };
  };

  const fetchData = async () => {
    try {
      const data = await getData(authenticatedUserId);

      setSubscription(data.subscription);
      setNbGenolists(data.nbGenolists);
      setNbProjects(data.nbProjects);

      const dataDashToSet = dataDash;
      dataDashToSet.datasets.data = [data.occupiedDashes[0], data.subscription.availableDashes];
      setDataDash(dataDashToSet);

      const dataTopDash = getTopViewsDash();
      setTopDash(dataTopDash);

      const dataTopGenolists = getTopViewsGenolists();
      setTopGenolists(dataTopGenolists);
    } catch (error) {
      console.error("Error fetching data", error);
      setError("An error occurred while fetching data. Please try again. Detail : " + error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticatedUserId) {
      fetchData();
    }
  }, [user, dataDash]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultInfoCard
                icon="card_membership"
                title="Your subscription"
                value={loading ? "Loading..." : subscription.level}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultInfoCard
                icon="folder_copy"
                title="Your Ongoing Projects"
                value={loading ? "Loading..." : nbProjects}
                color="info2"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultInfoCard
                icon="list_alt"
                title="Your Genolists"
                value={loading ? "Loading..." : nbGenolists}
                color="success"
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              {loading ? (
                <Card sx={{ height: "100%" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h6">My Dash</MDTypography>
                  </MDBox>

                  <MDBox p={3}>
                    <Skeleton variant="rectangular" height={500} />
                  </MDBox>
                </Card>
              ) : (
                <DashChart dataDash={dataDash} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              {loading ? (
                <Card sx={{ height: "100%" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pt={2}
                    px={2}
                  >
                    <MDTypography variant="h6">Your Timeline</MDTypography>
                  </MDBox>

                  <MDBox p={3}>
                    <Skeleton variant="rectangular" height={500} />
                  </MDBox>
                </Card>
              ) : (
                <Timeline userId={authenticatedUserId} />
              )}
            </Grid>
          </Grid>
        </MDBox>
        {/*<Grid container spacing={3}>
          <Grid item xs={12} s={6} lg={6}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Top Trending Genolists
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                {loading ? (
                  <TableSkeleton columns={3} rows={5} />
                ) : (
                  <TopViews data={fakeDataGenolists} />
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} s={6} lg={6}>
            <Card xs={12} s={6}>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Top Trending Dash
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                {loading ? (
                  <TableSkeleton columns={3} rows={5} />
                ) : (
                  <TopViews data={fakeDataDash} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>*/}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SciliciumDashboard;
