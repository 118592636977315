// UI components
import { Modal, Box, Typography } from "@mui/material";

// Styling
import colors from "assets/theme/base/colors";

const WarningModal = ({ open, onClose, title, message, action }) => {
  const modalStyle = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: colors.light.main,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" textAlign="center" variant="h4" component="h2">
          {title}
        </Typography>
        {message}
        {action}
      </Box>
    </Modal>
  );
};

export default WarningModal;
