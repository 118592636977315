import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import APIService from "services/DjangoAPI";

import { getAuthenticatedUserId } from "tokenHelper";

// Loader
import DescriptionTile from "./DescriptionTile";
import GenolistsTile from "./GenolistsTile";
import GeneralTile from "./GeneralTile";
import AlertSnackbar from "sciliciumLayouts/components/AlertSnackbar";
import ResultsOverviewTile from "./ResultsOverviewTile";
import MDTypography from "components/MDTypography";

/**
 * This component displays a project details. It makes use of 3 sub-components each
 * responsible for a specific part of the project :
 * - General tile displays Project fields and a form to update it
 * - Description tile displays samples, comparisons and factors
 * - Genolist tile displays genolists associated with the project
 */
const Project = () => {
  const { id } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isOwner, setIsOwner] = useState(false); // Whether to enable editing mode
  const userId = getAuthenticatedUserId();
  const [tabValue, setTabValue] = useState("1");

  useEffect(() => {
    const getProjectData = async (id) => {
      try {
        const response = await APIService.getProject(id);
        const createdAtDate = new Date(response.data.creation_date);
        setProjectData({ ...response.data, createdAt: createdAtDate });

        setIsOwner(parseInt(response.data.owner.id) === parseInt(userId));
      } catch (error) {
        setError("Error fetching project data");
      }
    };
    getProjectData(id);
  }, [id, userId]);

  const cleanMessage = () => {
    setError("");
    setSuccess("");
  };

  const handleFormSubmit = async (formData) => {
    try {
      await APIService.putProject(formData);
      setSuccess("Your project has been updated");
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AlertSnackbar
        open={success !== ""}
        onClose={cleanMessage}
        severity="success"
        message={success}
      />
      <AlertSnackbar open={error !== ""} onClose={cleanMessage} severity="error" message={error} />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item md={12} xl={12}>
            <MDBox pb={3} pr={3} pl={3}>
              <Card>
                <MDBox pb={3} pl={3} pt={3}>
                  <MDTypography variant="h4" fontWeight="medium">
                    Details for project {projectData?.name}
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>

            <TabContext value={tabValue}>
              <MDBox pr={3} pl={3}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="project tabs"
                  sx={{ mb: 0 }}
                >
                  <Tab label="General Info" value="1" />
                  <Tab label="Metadata" value="2" />
                  <Tab label="Results overview" value="3" />
                  <Tab label="Genolists" value="4" />
                </Tabs>
              </MDBox>
              <TabPanel value="1">
                <GeneralTile data={projectData} userIsOwner={isOwner} onSubmit={handleFormSubmit} />
              </TabPanel>
              <TabPanel value="2">
                {/* Metadata component here */}
                <DescriptionTile
                  id={id}
                  setSuccess={setSuccess}
                  setError={setError}
                  userIsOwner={isOwner}
                />
              </TabPanel>
              <TabPanel value="3">
                <ResultsOverviewTile projectId={id} projectStatus={projectData?.status} />
              </TabPanel>
              <TabPanel value="4">
                <GenolistsTile projectId={id} projectStatus={projectData?.status} />
              </TabPanel>
            </TabContext>
          </Grid>
          <Grid item md={12} xl={7}></Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Project;
