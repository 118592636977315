import React from 'react';
import Plot from 'react-plotly.js';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const BoxPlot = ({ data, title, ytitle, xtitle }) => {

    if (Object.keys(data).length === 0) {
        return <MDBox pt={3} px={3}><MDTypography variant="h6" fontWeight="medium">No data to display </MDTypography></MDBox>;
    }

  
  const trace1 = {
    y: data.control,
    type: 'box',
    name: 'Control',
    marker: {color: '#03CEA4'},
  };

  const trace2 = {
    y: data.condition,
    type: 'box',
    name: 'Condition',
    marker: {color: '#272838'},
  };


  const layout = {
    title: {title},
    yaxis: {
      title: {ytitle},
      zeroline: false,
    },
    xaxis: {
      title: {xtitle},
      zeroline: false,
    },
  };

  return <Plot data={[trace1,trace2]} layout={layout} />;
};

export default BoxPlot;