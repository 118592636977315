import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import EnrichmentTable from "../Tables/EnrichmentTable";
import APIService from "services/DjangoAPI";
import EnrichmentDotPlot from "../Charts/EnrichmentDotPlot";

function Enrichment(props) {
  const [loading, setLoading] = useState(true);
  const [enrichmentData, setEnrichmentData] = useState([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const plotRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await APIService.getGOvaluesByGenolist(props.genolistId);
        data.sort((a, b) => b.count / b.nb_genes_in_term - a.count / a.nb_genes_in_term);
        setEnrichmentData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [props.genolistId]);

  useEffect(() => {
    if (plotRef.current) {
      setMaxHeight(plotRef.current.clientHeight);
    }
  }, [plotRef]);

  const topEnrichmentData = enrichmentData
    .slice()
    .sort((a, b) => b.count / b.nb_genes_in_term - a.count / a.nb_genes_in_term)
    .slice(0, 10);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={7}>
        <EnrichmentTable loading={loading} enrichmentData={enrichmentData} />
      </Grid>
      <Grid item xs={12} sm={5} ref={plotRef}>
        <EnrichmentDotPlot
          loading={loading}
          topEnrichmentData={topEnrichmentData}
          setMaxHeight={setMaxHeight}
        />
      </Grid>
    </Grid>
  );
}

export default Enrichment;
