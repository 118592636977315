// Hooks
import { useEffect, useState } from "react";

// Data access
import APIService from "services/DjangoAPI";

// UI Components
import { Card, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "sciliciumLayouts/components/Tables/DataTable";
import colors from "assets/theme/base/colors";

import LinkButton from "sciliciumLayouts/components/Buttons/LinkButton";
import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";

const GenolistsTile = ({ projectId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [genolists, setGenolists] = useState([]);

  /**
   * On render, retrieves the list of Genolists for current project.
   */
  useEffect(() => {
    const getGenolists = async () => {
      setLoading(true);
      try {
        const response = await APIService.getGenolistsByProject(projectId);
        let genolistRows = response.data.map((g, ix) => ({
          name: g.name,
          deg: g.deg.total,
          link: (
            <LinkButton key={ix} text="View" to={`/genolists/${g.id}`} variant="gradient" />
          ),
        }));
        setGenolists(genolistRows);
        setError(undefined);
      } catch (error) {
        setError(error.message || "Data could not be retrieved.");
      } finally {
        setLoading(false);
      }
    };
    getGenolists();
  }, [projectId]);

  // Generates table data to be passed to the DataTable
  const genolistsTableData = {
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "deg", accessor: "deg" },
      { Header: "link", accessor: "link", width: "10%" },
    ],
    rows: genolists,
  };

  return (
    <Card>
      <MDBox py={3} px={3}>
        <MDTypography variant="h5">Genolists</MDTypography>
        {loading && (
          <MDBox fullWidth sx={{ display: "flex", flexDirection: "column" }}>
            <Skeleton height={50} />
            <TableSkeleton mt={2} />
          </MDBox>
        )}
        {error && !loading && <div>{error}</div>}
        {genolists.length === 0 && !loading && (
          <MDBox
            height={200}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <MDTypography variant="h5">No Genolist yet</MDTypography>
            <MDTypography mt={1} variant="body2" align="center">
              Genolists related to this project
              <br />
              will be featured here
            </MDTypography>
          </MDBox>
        )}
        {genolists.length > 0 && (
          <MDBox>
            <DataTable
              table={genolistsTableData}
              entriesPerPage={5}
              showTotalEntries={false}
              isSorted={true}
              canSearch
              noEndBorder
            />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
};

export default GenolistsTile;
