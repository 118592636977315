/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'

import DataTable from "examples/Tables/DataTable";
import Cell from '../Cell';

// Material Dashboard 2 PRO React examples
import MDButton from "components/MDButton";

class ChemicalsTable extends React.Component{

  formatChemicals=()=>{
    const tableRows = [];
    this.props.data.map((row, key)=> {
      const img_url = "https://strapi.scilicium.com"+row.structure[0].url
      const format_row = {
        name: <Cell image={img_url} name={row.name} />,
        CAS: row.CAS,
        view: <MDButton color="primary">View</MDButton>
      }
      tableRows.push(format_row)

    })
    return tableRows
  }

  render() {
    const dataTableData = {
      columns : [
        { Header: "name", accessor: "name", width: "55%" },
        { Header: "CAS", accessor: "CAS" },
        { Header: "view", accessor: "view" },
      ],

      rows:[]
    };
    dataTableData.rows = this.formatChemicals();
    return (
      <DataTable table={dataTableData} entriesPerPage={true} />
    );
  }
}

export default ChemicalsTable;
