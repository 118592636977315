// Hooks
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Data access
import APIService from "services/DjangoAPI";

// UI components
import { Card, Chip, Typography } from "@mui/material";
import WarningModal from "sciliciumLayouts/components/WarningModal";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import InfoButton from "sciliciumLayouts/components/Buttons/InfoButton";

import TableSkeleton from "sciliciumLayouts/components/Skeletons/TableSkeleton";

/**
 * Used to handle differents states
 */
const DataStatus = Object.freeze({
  LOADING: Symbol("Loading"),
  ERROR: Symbol("Error"),
  EMPTY: Symbol("No data"),
  DATA: Symbol("Data"),
});

const DescriptionTile = ({ id, setSuccess, setError, userIsOwner }) => {
  const [dataStatus, setDataStatus] = useState(DataStatus.LOADING);
  const [data, setData] = useState(null);
  const [onDelete, setOnDelete] = useState(false);
  const [onOverwrite, setOnOverwrite] = useState(false);

  // Functions to handle modal clicks
  const handleDeleteClick = () => setOnDelete(true);
  const handleDeleteClose = () => setOnDelete(false);
  const handleOverwriteClick = () => setOnOverwrite(true);
  const handleOverwriteClose = () => setOnOverwrite(false);

  // State handling table data
  const [
    { factorRows, factorColumns, sampleRows, sampleColumns, comparisonRows, comparisonColumns },
    setTableData,
  ] = useState({
    factorRows: [],
    factorColumns: [],
    sampleRows: [],
    sampleColumns: [],
    comparisonRows: [],
    comparisonColumns: [],
  });

  const factorsTableData = {
    columns: factorColumns,
    rows: factorRows,
  };

  const samplesTableData = {
    columns: sampleColumns,
    rows: sampleRows,
  };

  const comparisonsTableData = {
    columns: comparisonColumns,
    rows: comparisonRows,
  };

  useEffect(() => {
    getExtendedProjectData(id);
  }, []);

  const getExtendedProjectData = async (id) => {
    setDataStatus(DataStatus.LOADING);
    try {
      const response = await APIService.getExtendedProject(id);
      const createdAtDate = new Date(response.data.creation_date);
      setData({ ...response.data, createdAt: createdAtDate });

      let data = response.data;

      if (data.samples.length === 0 && data.comparisons.length === 0) {
        setDataStatus(DataStatus.EMPTY);
      } else if (data.samples.length > 0 && data.comparisons.length > 0) {
        // Calculate factor table data
        let calcFactorColumns = [
          { Header: "factor", accessor: "factor" },
          { Header: "levels", accessor: "levels" },
        ];

        let calcFactorRows = data.factors.map((f) => ({
          factor: f.name,
          levels: f.levels.map((level, ix) => (
            <Chip key={ix} sx={{ mr: "0.5rem" }} size="small" label={level} />
          )),
        }));

        // Calculate sample table data
        let calcSampleColumns = [
          { Header: "name", accessor: "name" },
          { Header: "species", accessor: "species" },
          { Header: "metadata", accessor: "metadata" },
        ];

        let calcSampleRows = data.samples.map((s) => ({
          name: s.name,
          species: `${s.species.genus} ${s.species.species} (${s.species.vernacular})`,
          metadata: s.factors.map((f, ix) => (
            <Chip key={ix} sx={{ mr: "0.5rem" }} size="small" label={f.level} />
          )),
        }));

        // Calculate comparison table data
        let calcComparisonColumns = [
          { Header: "name", accessor: "name" },
          { Header: "first condition", accessor: "first" },
          { Header: "second condition", accessor: "second" },
        ];
        let calcComparisonRows = data.comparisons.map((c, ix) => ({
          name: c.name,
          first: <Chip key={ix + "-1st"} mr={1} size="small" label={c.main_factor.level} />,
          second: <Chip key={ix + "-2nd"} mr={1} size="small" label={c.secondary_factor.level} />,
        }));

        // Update state with calculated data
        setTableData((prev) => ({
          ...prev,
          factorRows: calcFactorRows,
          factorColumns: calcFactorColumns,
          sampleRows: calcSampleRows,
          sampleColumns: calcSampleColumns,
          comparisonRows: calcComparisonRows,
          comparisonColumns: calcComparisonColumns,
        }));
        setDataStatus(DataStatus.DATA);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      setError("Error fetching project data");
      setDataStatus(DataStatus.ERROR);
    }
  };

  /**
   * To be called from warning modal.
   * Deletes data in DB and updates the state
   */
  const handleDeleteConfirm = async () => {
    setOnDelete(false);
    setDataStatus(DataStatus.LOADING);
    try {
      await APIService.deleteProjectDesc(data.id);
      setSuccess("Project data has been successfully deleted");
      setDataStatus(DataStatus.EMPTY);
      setData(null);
    } catch (apiError) {
      setError(apiError);
      if (data !== null) {
        setDataStatus(DataStatus.DATA);
      } else {
        setDataStatus(DataStatus.EMPTY);
      }
    }
  };

  return (
    <>
      <WarningModal
        open={onDelete}
        onClose={handleDeleteClose}
        title={"Are you sure you want to delete this project's data?"}
        message={
          <Typography id="modal-modal-description" variant="body1" sx={{ my: 3 }}>
            <strong>Samples</strong>, <strong>Experimental factors</strong> and{" "}
            <strong>Comparisons</strong> will be removed from your project.
          </Typography>
        }
        action={
          <MDButton color="error" variant="gradient" onClick={handleDeleteConfirm}>
            Delete project data
          </MDButton>
        }
      />
      <WarningModal
        open={onOverwrite}
        onClose={handleOverwriteClose}
        title={"This project already has data uploaded"}
        message={
          <Typography id="modal-modal-description" variant="body1" sx={{ my: 3 }}>
            <strong>Samples</strong>, <strong>Experimental factors</strong> and{" "}
            <strong>Comparisons</strong> will be overwritten. Continue?
          </Typography>
        }
        action={
          <Link to={`/projects/${data?.id}/upload`}>
            <MDButton color="primary" variant="gradient" sx={{ mr: 2, width: "100%" }}>
              Upload a description file
            </MDButton>
          </Link>
        }
      />
      <Card>
        <MDBox py={3} px={3}>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography variant="h5">Description</MDTypography>
            {data?.status === "APPROVED" && dataStatus === DataStatus.DATA && (
              <InfoButton message="The info below comes from an Excel project description file. If you want to make changes, you will need to upload a new file (this will overwrite current data)." />
            )}
          </MDBox>

          {dataStatus === DataStatus.ERROR && (
            <MDBox
              height={200}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <MDTypography variant="h5">Oops</MDTypography>
              <MDTypography variant="body2">Something went wrong.</MDTypography>
            </MDBox>
          )}
          {dataStatus === DataStatus.LOADING && (
            <MDBox display="flex" flexDirection="column" gap={4}>
              <TableSkeleton key="factors" cols={2} />
              <TableSkeleton key="samples" />
              <TableSkeleton key="compars" />
            </MDBox>
          )}
          {dataStatus === DataStatus.EMPTY && (
            <MDBox
              height={200}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <MDTypography variant="h5">No data yet</MDTypography>
              {data?.status !== "APPROVED" ? (
                <MDTypography variant="body2">
                  Once your project is approved, you will be able to upload a description file from
                  here.
                </MDTypography>
              ) : (
                <Link to={`/projects/${data?.id}/upload`}>
                  <MDButton color="primary" variant="gradient" sx={{ mr: 2, width: "100%" }}>
                    Upload a description file
                  </MDButton>
                </Link>
              )}
            </MDBox>
          )}
          {dataStatus === DataStatus.DATA && (
            <MDBox>
              <MDTypography mt={1} variant="subtitle2">
                Experimental factors
              </MDTypography>
              <DataTable
                table={factorsTableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={true}
                noEndBorder
              />
              <MDTypography mt={2} variant="subtitle2">
                Samples
              </MDTypography>
              <DataTable
                table={samplesTableData}
                entriesPerPage={{ defaultValue: 5 }}
                canSearch
                showTotalEntries={false}
                isSorted={true}
                noEndBorder
              />
              <MDTypography mt={2} variant="subtitle2">
                Comparisons
              </MDTypography>
              <DataTable
                table={comparisonsTableData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
              />
              {data.status === "APPROVED" && userIsOwner && (
                <MDBox mt={4}>
                  <MDButton
                    color="primary"
                    variant="gradient"
                    sx={{ mr: 2 }}
                    onClick={handleOverwriteClick}
                  >
                    Upload a description file
                  </MDButton>
                  <MDButton color="error" variant="outlined" onClick={handleDeleteClick}>
                    Delete project data
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          )}
        </MDBox>
      </Card>
    </>
  );
};

export default DescriptionTile;
