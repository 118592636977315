import React, { useEffect, useState } from "react";
import Plot from 'react-plotly.js';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ScatterPlot = ({ data }) => {

    if (Object.keys(data).length === 0) {
        return <MDBox pt={3} px={3}><MDTypography variant="h6" fontWeight="medium">No data to display </MDTypography></MDBox>;
    }


    // transform data to an array of { x, y, name } objects
    const names_up = [];
    const names_down = [];
    const values_up = [];
    const values_down = [];
    const xs_up = [];
    const xs_down= [];

    Object.entries(data.enrichment.fc).map((name, value)=> {
        
        if(name[1] >=0){
            values_up.push(name[1]);
            xs_up.push(value);
            names_up.push(name[0])  
        }else{
            values_down.push(name[1]);
            xs_down.push(value);
            names_down.push(name[0])  
        }
        
        
    })

    const trace1 = {
        x: xs_up,
        y: values_up,
        mode: 'markers',
        type: 'scatter',
        name: 'Up regulated',
        text: names_up,
        marker: {
            color: 'rgba(255, 0, 0,1)',
        }
    };

    const trace2 = {
        x: xs_down,
        y: values_down,
        mode: 'markers',
        type: 'scatter',
        name: 'Down regulated',
        text: names_down,
        marker: {
            color: 'rgba(0, 128, 255,1)',
        }
    };

    return <Plot data={[trace1,trace2]} />;
};

export default ScatterPlot;
