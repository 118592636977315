import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "sciliciumLayouts/components/Footer";
import questions from "./data/questions";
import MDTypography from "components/MDTypography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import MDBox from "components/MDBox";
import LinkButton from "sciliciumLayouts/components/Buttons/LinkButton";
import { ExpandMore } from "@mui/icons-material";

/**
 * Displays Frequently asked questions.
 * List of questions are listed in data/questions.json.
 * The JSON is structured by categories and each question has
 * a title and a description. To increase readability, the descriptions are splitted
 * in list of strings, where each string is a paragraph.
 */
const FAQ = () => {

  const renderQuestions = (questions) => {
    return questions.map((question, index) => (
      <Accordion key={`question-${index}`}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <MDTypography variant="h5">{question.title}</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
        {question.description.map((p, i) => <MDTypography fontSize={18} mb={2} key={i} variant="body1">{p}</MDTypography>)}
        </AccordionDetails>
      </Accordion>
    ));
  };

  const renderCategories = () => {
    return questions.map((category) => (
      <MDBox key={`box-${category.category}`} display="flex" flexDirection="column" gap={1}>
        <MDTypography variant="h4">{category.category}</MDTypography>
        {renderQuestions(category.questions)}
      </MDBox>
    ));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography my={2} variant="h2" textAlign="center" >Frequently asked questions</MDTypography>
      <MDBox display="flex" flexDirection="column" gap={2} maxWidth="900px" m="auto" >
      {renderCategories()}
      <MDBox m="auto" display="flex" flexDirection="column" alignItems="center" gap={2} justifyContent="center" minHeight={200}>
        <MDTypography variant="h5">
          You didn't find your answer or need more information?
        </MDTypography>
        <LinkButton to="https://scilicium.com/contact/" text="Contact us" variant="gradient" />
      </MDBox>
      </MDBox>
      
      <Footer />
    </DashboardLayout>
  );
};
export default FAQ;
