/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//react
import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

import { DOMAIN } from "constants";
import { GO_DATABASE } from "constants";

function ListCell({ name, description, id, type }) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    switch (type) {
      case "genolist":
        setUrl(DOMAIN + "/genolists/" + id);
        break;
      case "project":
        setUrl(DOMAIN + "/projects/" + id);
        break;
      case "sample":
        setUrl(DOMAIN + "/samples/" + id);
        break;
      case "goterm":
        setUrl(GO_DATABASE + id);
        break;
      default:
        setUrl(DOMAIN + "/dashboard/");
    }
  }, []);

  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDBox display="flex" flexDirection="column">
        <Link to={url}>
          <MDTypography variant="button" fontWeight="medium">
            {name}
          </MDTypography>
        </Link>
        <MDTypography variant="button" fontWeight="regular">
          <MDTypography component="span" variant="button" fontWeight="regular">
            {description}
          </MDTypography>{" "}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the TopViewsCell
ListCell.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default ListCell;
