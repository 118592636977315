import React from "react";
import { Navigate  } from "react-router-dom";

const withAuth = (Component) => {
  const AuthRoute = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return <Navigate  to="/login" />;
    }

    return <Component {...props} />;
  };

  return AuthRoute;
};

export default withAuth;
