import { Card, Grid, Skeleton } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Plot from "react-plotly.js";

const GenocountsSkeleton = () => {
  return (
    <Card>
      <MDBox ml={3} mt={3} mb={3}>
        <MDTypography variant="h5" fontWeight="medium">
          Comparing expression of genes
        </MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Skeleton>
            <Plot />
          </Skeleton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton>
            <Plot />
          </Skeleton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GenocountsSkeleton;
