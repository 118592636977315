import { useEffect, useState } from "react";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "sciliciumLayouts/components/Tables/DefaultCell";
import ListCell from "sciliciumLayouts/components/Tables/ListCell";

function ListSamples({ listSamples, normalization, showStatusSample, showFactors }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    // Initialize columns with static ones
    let columns = [
      { Header: "Unique Nuclear Transcripts", accessor: "refseq_umi", align: "center" },
      { Header: "Nb of Detected Genes", accessor: "genes_detected", align: "center" },
    ];

    // Initialize an object to hold factor columns
    let factorColumns = {};

    // Prepare rows and dynamically add factor data
    const newRows = listSamples.map((sample) => {
      // Prepare a row object with base data
      let row = {
        name: (
          <ListCell name={sample.name} id={sample.id} type="sample">
            {sample.name}
          </ListCell>
        ),
        quality_control: <DefaultCell>{sample.quality_control}</DefaultCell>,
        refseq_umi: <DefaultCell>{sample.refseq_umi}</DefaultCell>,
        genes_detected: <DefaultCell>{sample.genes_detected}</DefaultCell>,
        normalization:
          normalization && normalization.length > 0 ? (
            <DefaultCell>
              {sample.factors
                .map((factor) => `${factor.factor_name.name}: ${factor.level}`)
                .join(", ")}
            </DefaultCell>
          ) : undefined,
      };

      // Add factor levels to row and factorColumns if showFactors is true
      if (showFactors) {
        sample.factors.forEach((factor) => {
          const factorName = factor.factor_name.name;
          const factorLevel = factor.level;

          // Add factor to row
          row[factorName] = <DefaultCell>{factorLevel}</DefaultCell>;

          // Add column for this factor if not already added
          if (!factorColumns[factorName]) {
            factorColumns[factorName] = {
              Header: factorName,
              accessor: factorName,
              align: "center",
            };
          }
        });
      }

      return row;
    });

    // Add the normalization column if normalization is provided and not empty
    if (normalization && normalization.length > 0) {
      columns.push({
        Header: "Factor for Normalization",
        accessor: "normalization",
        align: "center",
      });
    }

    // Add the status column if showStatusSample is true
    if (showStatusSample) {
      columns.unshift({
        Header: "Status",
        accessor: "quality_control",
        align: "center",
      });
    }

    columns.unshift({ Header: "name", accessor: "name", width: "55%" });
    const finalColumns = [...columns, ...Object.values(factorColumns)];

    setColumns(finalColumns);
    setRows(newRows);
  }, [listSamples, normalization, showStatusSample, showFactors]);

  const dataList = {
    columns: columns,
    rows,
  };

  return (
    <DataTable
      table={dataList}
      entriesPerPage={true}
      showTotalEntries={true}
      isSorted={false}
      noEndBorder
    />
  );
}

export default ListSamples;
