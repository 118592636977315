import Plot from "react-plotly.js";
import colors from "assets/theme/base/colors";

const BoxPlotBis = ({ data, comparison, title, ytitle, xtitle, legend }) => {
  // if (data === undefined) {
  //   return (
  //     <Skeleton>
  //       <Plot />
  //     </Skeleton>
  //   );
  // }
  const trace1 = {
    y: data.main_factor,
    type: "box",
    name: `${comparison.main_factor.level}<br>(${data.main_factor.length} samples)`,
    marker: { color: colors.success.main },
  };

  const trace2 = {
    y: data.secondary_factor,
    type: "box",
    name: `${comparison.secondary_factor.level}<br>(${data.secondary_factor.length} samples)`,
    marker: { color: colors.info.main },
  };

  const layout = {
    title: title,
    yaxis: {
      title: ytitle,
      zeroline: false,
    },
    autosize: true,
    showlegend: legend,
    margin: {
      l: 80,
      r: 20,
      b: 100,
      t: 80,
      pad: 4,
    },
  };

  return (
    <Plot
      data={[trace1, trace2]}
      layout={layout}
      style={{ width: "100%", height: "100%" }}
      useResizeHand={true}
    />
  );
};

export default BoxPlotBis;
