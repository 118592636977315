import { useEffect, useState } from "react";
import APIService from "services/DjangoAPI";
import Plot from "react-plotly.js";
import MDBox from "components/MDBox";
import { Card, IconButton, Skeleton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import MDTypography from "components/MDTypography";
import CustomTooltip from "sciliciumLayouts/components/customToolTip";
import toolTipsTexts from "assets/toolTipsTexts";

function SamplesCorrelation(props) {
  const [correlationMatrix, setCorrelationMatrix] = useState([]);
  const [sampleNames, setSampleNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFactor, setSelectedFactor] = useState("");
  const [factorNames, setFactorNames] = useState([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await APIService.getSamplesCorrelationByProject(props.projectId);
        const { sample_names, correlation_matrix, factor_names } = response.data;

        setCorrelationMatrix(correlation_matrix);
        setSampleNames(sample_names);
        setFactorNames(factor_names);
        setSelectedFactor(factor_names[0] || ""); // Default to the first factor name if it exists
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Samples correlation data:", error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [props.projectId]);

  const renderPlot = () => {
    return (
      <MDBox pb={1} pl={1}>
        <div className="custom-plot-container">
          <Plot
            data={[
              {
                z: correlationMatrix,
                x: sampleNames,
                y: sampleNames,
                type: "heatmap",
                colorscale: "Viridis",
                showscale: true,
              },
            ]}
            layout={{
              xaxis: { tickangle: -45 },
              yaxis: { autorange: "reversed" },
              margin: { l: 200, r: 0, b: 180, t: 50, pad: 4 },
              autosize: true,
            }}
            style={{ width: "100%", height: "100%" }}
            useResizeHandler={true}
          />
        </div>
      </MDBox>
    );
  };

  return (
    <Card>
      <MDBox pb={2}>
        <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Samples Correlation
          </MDTypography>
          <CustomTooltip title={toolTipsTexts.samplesCorrelation}>
            <IconButton sx={{ py: 0, pr: 3 }}>
              <HelpIcon />
            </IconButton>
          </CustomTooltip>
        </MDBox>
      </MDBox>
      {loading ? (
        <Skeleton>
          <Plot />
        </Skeleton>
      ) : (
        <MDBox>
          <MDBox pl={2} pb={2}>
            {renderPlot()}
          </MDBox>
        </MDBox>
      )}
    </Card>
  );
}

export default SamplesCorrelation;
