import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import BoxPlotWrapper from "../BoxPlotWrapper";
import "../customStylingComponents.css";

function BoxPlotsForGenocounts(props) {
  const listOfGenes = props.listOfGenes;
  const genolist = props.genolist;
  // This code was inserted to get the min and max values of the boxplots to set the same scale for both.
  // As plotly apparently doesn't like hot reloads, we won't use it for now. I let it here in case we need it later.
  // (and because it took me the whole day to figure it out :D I have attachment issues with my code)
  // const [minValue, setMinValue] = useState(1000000);
  // const [maxValue, setMaxValue] = useState(0);
  //
  // const updateMinMaxValues = (minMaxBoxplot) => {
  //   setMinValue((prevMinValue) => {
  //     if (minMaxBoxplot.min < prevMinValue) {
  //       console.log("Updated minValue:", prevMinValue); // Access updated minValue
  //       return minMaxBoxplot.min;
  //     }
  //     return prevMinValue;
  //   });

  //   setMaxValue((prevMaxValue) => {
  //     if (minMaxBoxplot.max > prevMaxValue) {
  //       console.log("Updated maxValue:", prevMaxValue); // Access updated maxValue
  //       return minMaxBoxplot.max;
  //     }
  //     return prevMaxValue;
  //   });
  // };

  // useEffect(() => {
  //   // console.log("minValue", minValue);
  //   // console.log("maxValue", maxValue);
  // }, [minValue, maxValue]);

  return (
    <MDBox mb={3}>
      <Card sx={{ height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
        <MDBox pb={3}>
          <MDBox ml={3} mt={3} mb={3}>
            <MDTypography variant="h5" fontWeight="medium">
              Comparing expression of genes
            </MDTypography>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <BoxPlotWrapper
                listOfGenes={listOfGenes}
                defaultIndex={0}
                genolist={genolist}
                className="chart-container"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxPlotWrapper
                listOfGenes={listOfGenes}
                defaultIndex={1}
                genolist={genolist}
                className="chart-container"
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}
export default BoxPlotsForGenocounts;
