// Hooks
import { useEffect, useState } from "react";

// UI components
import { Snackbar, Alert } from "@mui/material";

const AlertSnackbar = ({ open, onClose, message, severity }) => {
  const [alertMessage, setAlertMessage] = useState();

  useEffect(() => {
    if (message !== "") setAlertMessage(message);
  }, [message]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={onClose}
      autoHideDuration={5000}
    >
      <Alert variant="filled" severity={severity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};
export default AlertSnackbar;
