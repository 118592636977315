import { useState } from "react";
import APIService from "services/DjangoAPI";
import { saveAs } from "file-saver";
import { Button } from "@mui/material"
import colors from "assets/theme/base/colors";

const DownloadCSVButton = ({ genolistId, genolistName, projectName }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const downloadCSV = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await APIService.getTranscriptoValuesToDownload(
        genolistId
      );
      const contentDisposition = response.headers["content-disposition"];
      let filename = `${projectName}_${genolistName}_transcripto_values.csv`; // Default filename
      // tronquer le nom du fichier pour éviter les erreurs de longueur de nom de fichier
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      saveAs(response.data, filename);
    } catch (error) {
      setError(error);
      console.error("Error downloading CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={downloadCSV}
        disabled={loading}
        color="primary"
        sx={{color:colors.primary.main}}
      >
        {loading ? "Downloading..." : "Download CSV"}
      </Button>
      {error && <div>{error.message}</div>}
    </div>
  );
};

export default DownloadCSVButton;
