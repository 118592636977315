import axios from "axios";
import { API } from "./constants";

const axiosInstance = axios.create({
  baseURL: API,
  timeout: 200000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const refreshAccessToken = async () => {
  try {
    const refresh_token = localStorage.getItem("refresh_token");
    const response = await axiosInstance.post("/token/refresh/", { refresh: refresh_token });
    const access_token = response.data.access;
    localStorage.setItem("access_token", access_token);
    return access_token;
  } catch (error) {
    console.log("Error refreshing access token:", error);
    return Promise.reject(error);
  }
};

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeToAccessTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onAccessTokenRefreshed = (access_token) => {
  refreshSubscribers.forEach((cb) => cb(access_token));
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers["Authorization"] = `JWT ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const access_token = await refreshAccessToken();
          onAccessTokenRefreshed(access_token);
          isRefreshing = false;
        } catch (error) {
          isRefreshing = false;
          return Promise.reject(error);
        }
      }

      return new Promise((resolve) => {
        subscribeToAccessTokenRefresh((access_token) => {
          originalRequest.headers["Authorization"] = "JWT " + access_token;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    // specific error handling done elsewhere
    return Promise.reject({ ...error });
  }
);

export default axiosInstance;
